import React from 'react';

// Components
import { DialogTitle } from '@mui/material';
import { IconButtonCustom } from '../../atoms';

// Assets
import { ReactComponent as CloseIcon } from '../../../assets/icons/Modales/Icono-Cerrar.svg';
import { ReactComponent as CloseIconAdvertencia } from '../../../assets/icons/Modales/IconoAdvertencia.svg';
import { ReactComponent as CloseIconError } from '../../../assets/icons/Modales/IconoError.svg';
import { ReactComponent as CloseIconSuccess } from '../../../assets/icons/Modales/IconoSuccess.svg';

const DialogTitleCustom = ({
  disabledIconClose = false,
  children = null,
  onClose = () => null,
  severity = 'info',
}) => {
  const renderIcon = () => {
    let icon = null;
    switch (severity) {
      case 'info':
        icon = <CloseIcon className=" w-[44px] h-[44px]" />;
        break;
      case 'error':
        icon = <CloseIconError className="w-[44px] h-[44px]" />;
        break;
      case 'warning':
        icon = <CloseIconAdvertencia className="w-[44px] h-[44px]" />;
        break;
      case 'success':
        icon = <CloseIconSuccess className="w-[44px] h-[44px]" />;
        break;
      default:
        icon = <CloseIcon className="w-[44px] h-[44px] " />;
        break;
    }
    return icon;
  };

  return (
    <DialogTitle className="pl-5 pr-2 m-0 py-0 h-0 bg-red-900 flex justify-end ">
      <div>
        {children}
        {!disabledIconClose && (
          <IconButtonCustom icon={renderIcon()} onClick={onClose} size={44} />
        )}
      </div>
    </DialogTitle>
  );
};

export default DialogTitleCustom;
