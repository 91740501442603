export const metaData = {
  '/home': {
    image:
      'https://cotizaseguros.s3.us-east-2.amazonaws.com/ogFB/Logo-Asegurame-03.jpg',
    title: 'Asegurame | Cotiza y compra tu seguro en línea',
    description:
      'Plataforma en línea para cotizar y comprar seguros de diferentes aseguradoras en Costa Rica. Ahora asegúrate de la forma más fácil.',
    keywords:
      'asegurame, seguros, cotiza seguro, comprar seguro, seguros en linea, seguros online, aseguradoras en costa rica, seguros costa rica, seguro de viaje, seguro deducible automovil, seguro enfermedades graves, ins, instituto nacional de seguros, uniserse, correduria de seguros',
  },
  '/cotizar': {
    image:
      'https://cotizaseguros.s3.us-east-2.amazonaws.com/ogFB/Logo-Asegurame-03.jpg',

    title: 'Asegurame | Cotiza y compra tu seguro en líne',
    description:
      'Descubre las diferentes opciones de seguros que puedes cotizar y comprar. Seguro de Viaje, Seguro de Deducible Automóvil y Seguro de Autoexpedible Enfermedades Graves.',
    keywords:
      'asegurame, seguros, cotiza seguro, comprar seguro, seguros en linea, seguros online, aseguradoras en costa rica, seguros costa rica, seguro de viaje, seguro deducible automovil, seguro enfermedades graves, ins, instituto nacional de seguros, uniserse, correduria de seguros',
  },
  '/conocenos': {
    image:
      'https://cotizaseguros.s3.us-east-2.amazonaws.com/ogFB/Logo-Asegurame-03.jpg',

    title: 'Asegurame | Facilitamos tus seguros',
    description:
      'La plataforma digital para cotizar y comprar seguros al instante con un objetivo: Simplificar los Seguros. En alianza con Correduría de seguros Uniserse en Costa Rica.',
    keywords:
      'asegurame, seguros, cotiza seguro, comprar seguro, seguros en linea, seguros online, aseguradoras en costa rica, correduria de seguros, uniserse',
  },
  '/blog': {
    image:
      'https://cotizaseguros.s3.us-east-2.amazonaws.com/ogFB/Logo-Asegurame-03.jpg',

    title: 'Asegurame | Aprendiendo sobre los seguros',
    description:
      'Aprende todo sobre el mundo de los seguros, para tomar la mejor decisión para ti y tu familia. Encuentra noticias y consejos para guiarte en tu camino.',
    keywords:
      'asegurame, seguros, cotizar seguros, comparar seguros, noticias de seguros, seguros digitales',
  },
  '/preguntas-frecuentes': {
    image:
      'https://cotizaseguros.s3.us-east-2.amazonaws.com/ogFB/Logo-Asegurame-03.jpg',

    title: 'Asegurame | Preguntas Frecuentes',
    description:
      '¿Dudas sobre seguros? Tenemos una amplia lista de preguntas y respuestas sobre todo tipo de seguros y el servicio de Asegurame.',
    keywords: 'asegurame, seguros, seguros en costa rica, uniserse.',
  },
  '/terminos-condiciones': {
    image:
      'https://cotizaseguros.s3.us-east-2.amazonaws.com/ogFB/Logo-Asegurame-03.jpg',

    title: 'Asegurame | Términos y Condiciones',
    description:
      'Consulta los términos y condiciones del servicio de Asegurame. Aquí encontrarás toda la información sobre el uso de nuestro sitio web, las políticas de privacidad, y las condiciones aplicables a la contratación de seguros en Costa Rica.',
    keywords: 'asegurame, seguros, seguros en costa rica, uniserse, términos y condiciones, políticas de privacidad, condiciones de uso.',
  },
  '/contactanos': {
    image:
      'https://cotizaseguros.s3.us-east-2.amazonaws.com/ogFB/Logo-Asegurame-03.jpg',
    title: 'Asegurame | La Correduría de Seguros Digital ',
    description:
      '¿Dudas sobre seguros? Contacta a un agente de seguros que te guiará a tomar la mejor decisión para asegurarte a ti, a tu familia, y tus bienes.',
    keywords:
      'asegurame, seguros, corredor de seguros, aseguradoras en costa rica, correduria de seguros, uniserse',
  },
  '/cotizar-deducible-vehiculo': {
    image:
      'https://cotizaseguros.s3.us-east-2.amazonaws.com/ogFB/Logo-Asegurame-03.jpg',

    title: 'Asegurame | Deducible Automóvil',
    description:
      'Si ya cuentas con un seguro de vehículo, este seguro te ofrece un control de costos en caso de accidentes. Descubre los beneficios, condiciones y aclara tus dudas de seguros de deducible de vehículos.',
    keywords:
      'asegurame, seguros, cotiza seguro, comprar seguro, seguros en linea, seguros online, aseguradoras en costa rica, seguros costa rica, seguro deducible de auto, deducible automóvil, ins, instituto nacional de seguros, uniserse, correduria de seguros',
  },
  '/cotizar-seguro-viaje': {
    image:
      'https://cotizaseguros.s3.us-east-2.amazonaws.com/ogFB/Logo-Asegurame-03.jpg',

    title: 'Asegurame | Seguro de Viaje',
    description:
      'Viaja sin preocupaciones, compra tu seguro de viaje completamente en línea. Protégete de inconvenientes y recibe apoyo personalizado en tus viajes. Conoce los beneficios, requisitos y aclara tus dudas.',
    keywords:
      'asegurame, seguro de viaje, asistencia al viajero, ins, instituto nacional de seguros, uniserse, correduria de seguros',
  },
  '/cotizar-enfermedades-graves': {
    image:
      'https://cotizaseguros.s3.us-east-2.amazonaws.com/ogFB/Logo-Asegurame-03.jpg',

    title: 'Asegurame | Seguro Médico',
    description:
      'Cuida de tu salud y tu bolsillo por consecuencias económicas de enfermedades graves, para que puedas concentrarte en tu recuperación. Descubre los beneficios, condiciones y aclara tus dudas de seguros médicos.',
    keywords:
      'asegurame, seguros, cotiza seguro, comprar seguro, seguros en linea, seguros online, aseguradoras en costa rica, seguros costa rica, seguro médico, seguro autoexpedible enfermedades graves, ins, instituto nacional de seguros, uniserse, correduria de seguros',
  },
};
