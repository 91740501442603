import { useEffect, useState } from 'react';

//Componentes
import { PatternFormat } from 'react-number-format';
import {
  ButtonCards,
  DateFechas,
  DatePickerCustom,
  MensajeError,
  Preguntita,
  SelectCustom,
  SelectFechaNacimiento,
  SelectMultiCustom,
  TextCustom,
  TextInputCustom,
  TextInputCustomMask,
} from '../../atoms';
import { CollapseForm } from '../../organisms';

// Style
import './style.css';

//hooks
import { useCotizador, useApp } from '../../../hooks/others';

// Utils
import { formatearDatosFormulario } from '../../../core/utils';
import { typesValidation } from '../../../common/types';

// Const
import { paisesList } from '../../../common/constants';

const PreguntasForm = ({
  titulo = '',
  tipoDato = '',
  action = () => null,
  valueForm = '',
  msgError = '',
  respuestas = [],
  placeholder = '',
  className = '',
  classNamePrincipal = '',
  descripcion = '',
  value = '',
  numeroElemento = 1,
  mostrarCampos = false,
  loader = false,
  mostrarError = false,
  alignError = '',
  requerido = false,
  mostrarRequerido = false,
  formatTel = '####-####',
  textAlignPlaca = false,
}) => {
  //context gneral de la app
  const { identidades } = useApp();

  const { dataCotizar, paso, comprandoOtroSeguro } = useCotizador();
  const [dato, setDato] = useState();
  const [desactivarFecha, setDesactivarFecha] = useState(false);

  useEffect(() => {
    if (valueForm === 'fechaViaje' && comprandoOtroSeguro) return;

    if (valueForm === 'Marca') {
      setDato(value.toLowerCase());
      return;
    }

    if (valueForm === 'Año') {
      setDato(Number(value));
    }

    setDato(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (paso === 4) {
      setDesactivarFecha(true);
      // Establecer la referencia a true para evitar actualizaciones futuras
    } else {
      setDesactivarFecha(false);
    }
  }, [paso]);

  const renderPreguntita = () => <Preguntita titulo={descripcion} />;

  const handleSetForm = val => {
    action(val, valueForm);
  };

  const handleOnClick = id => {
    setDato(id);
    action(id, valueForm);
  };

  const handleOnChange = id => {
    if (id.length === 0) {
      id = '';
    }

    setDato(id);
    action(id, valueForm);
  };

  const formatData = e => {
    const valoresFormateados = formatearDatosFormulario(
      e.target.value,
      valueForm,
    );

    e.target.value = valoresFormateados;
    action(e.target.value, valueForm);
  };

  const renderDato = () => {
    let customDato = undefined;

    switch (tipoDato) {
      case 'card': {
        customDato = (
          <>
            <div className="flex justify-center items-center">
              {respuestas.map((respuesta, index) => (
                <ButtonCards
                  key={index}
                  label={respuesta.titulo}
                  size="150px"
                  id={respuesta.titulo}
                  icon={respuesta.icono}
                  value={
                    (!value && !dataCotizar.dataSeguro.tipoPlan) ||
                    !dataCotizar.dataSeguro.codigo === 'SEG-V'
                      ? 'Plan Básico'
                      : dato
                  }
                  descripcion={respuesta.descripcion}
                  onClick={() => handleOnClick(respuesta.titulo)}
                  errorAlert={!dato ? mostrarError : false}
                />
              ))}
            </div>
            {mostrarError && (
              <MensajeError mensaje={msgError} alignError={alignError} />
            )}
          </>
        );
        break;
      }
      case 'select-multiple': {
        const options = paisesList;
        customDato = (
          <div style={{ maxWidth: 350, width: '100%' }}>
            <SelectMultiCustom
              value={dato}
              setValue={handleOnChange}
              errorAlert={!dato ? mostrarError : false}
              options={options}
              msgError={mostrarError ? msgError : ''}
              placeholder={placeholder}
            />
          </div>
        );
        break;
      }
      case 'select-simple': {
        let dataSelect = respuestas;
        if (valueForm === 'tipoIdentificacion') {
          dataSelect = identidades;
        }
        customDato = (
          <SelectCustom
            options={dataSelect}
            value={!dato ? '0' : dato}
            setValue={handleOnChange}
            placeholder={placeholder}
            defaultValue="0"
            errorAlert={!dato ? mostrarError : false}
            borderStyle={true}
            alinear="izquierda"
            msgError={mostrarError ? msgError : ''}
          />
        );
        break;
      }
      case 'input-fecha': {
        customDato =
          valueForm === 'fechaViaje' ? (
            <DateFechas
              value={dato}
              setValue={handleOnClick}
              errorAlert={!dato ? mostrarError : false}
              msgError={mostrarError ? msgError : ''}
            />
          ) : (
            <DatePickerCustom />
          );
        break;
      }
      case 'select-fecha-nacimiento': {
        customDato = (
          <div style={{ maxWidth: 600, width: '100%' }}>
            <SelectFechaNacimiento
              errorAlert={!dato ? mostrarError : false}
              setValue={handleOnClick}
              value={dato}
              isDisabled={desactivarFecha}
              msgError={
                mostrarError
                  ? !dato
                    ? msgError
                    : 'Tu fecha de nacimiento es inválida'
                  : ''
              }
            />
          </div>
        );
        break;
      }
      case 'input-numero': {
        customDato = (
          <TextInputCustom
            onChange={val => handleSetForm(val)}
            required
            value={dato}
            setValue={setDato}
            errorInput={mostrarError}
            placeholder={placeholder}
            borderStyle={true}
            onKeyUp={event => formatData(event, this)}
            msgError={mostrarError ? msgError : ''}
            disabled={`${valueForm === 'edad' ? true : false}`}
          />
        );
        break;
      }
      case 'input-telefono': {
        customDato = (
          <PatternFormat
            onValueChange={handleCelular}
            customInput={TextInputCustomMask}
            required
            value={dato}
            setValue={setDato}
            errorInput={mostrarError}
            type="tel"
            format={formatTel}
            mask=""
            placeholder={placeholder}
            borderStyle={true}
            msgError={
              mostrarError
                ? !dato
                  ? msgError
                  : 'Formato de teléfono no válido.'
                : ''
            }
            alignError="left"
          />
        );
        break;
      }
      case 'input-texto': {
        customDato = (
          <TextInputCustom
            onChange={val => handleSetForm(val)}
            required
            value={dato}
            setValue={setDato}
            errorInput={mostrarError}
            placeholder={placeholder}
            className="min-w-form "
            borderStyle={true}
            type="text"
            onKeyUp={event => formatData(event, this)}
            msgError={mostrarError ? msgError : ''}
            typesValidation={
              valueForm !== 'edad' && typesValidation.lettersExtend
            }
            disabled={valueForm === 'edad' ? true : false}
          />
        );
        break;
      }
      case 'input-texto-multi': {
        customDato = (
          <TextInputCustom
            onChange={val => handleSetForm(val)}
            required
            value={dato}
            setValue={setDato}
            errorInput={mostrarError}
            placeholder={placeholder}
            className="min-w-form"
            borderStyle={true}
            type="text"
            onKeyUp={event => formatData(event, this)}
            msgError={mostrarError ? msgError : ''}
            maxLength={valueForm === 'placa' ? 6 : 9}
            typesValidation={typesValidation.multiLettersExtend}
            loader={loader}
            textAlignPlaca={textAlignPlaca}
          />
        );
        break;
      }
      case 'input-correo': {
        customDato = (
          <TextInputCustom
            onChange={val => handleSetForm(val)}
            borderStyle={true}
            required
            value={dato}
            setValue={setDato}
            errorInput={mostrarError}
            placeholder={placeholder}
            type="email"
            msgError={
              mostrarError
                ? !dato
                  ? msgError
                  : 'Formato de correo electrónico no válido.'
                : ''
            }
          />
        );
        break;
      }
      case 'collapse-form':
        customDato = <CollapseForm action={action} />;
        break;
      default:
        customDato = undefined;
        break;
    }
    return customDato;
  };

  const handleCelular = values => {
    const { formattedValue } = values;
    action(formattedValue, valueForm);
  };

  return (
    <div
      className={`${
        numeroElemento > 2 && !mostrarCampos && paso === 2 ? 'hidden' : 'block'
      } ${classNamePrincipal}`}
    >
      <div className={`flex flex-col ${className} `}>
        <div className="flex gap-1">
          <TextCustom
            text={
              dataCotizar.seguro.codigo === 'SEG-V' &&
              tipoDato === 'select-fecha-nacimiento'
                ? 'Fecha de Nacimiento'
                : titulo
            }
            component="p"
            variant="p"
            className={`color-general fontPSemiBold text-base mb-3`}
            required={mostrarRequerido && requerido}
          />
          {descripcion && renderPreguntita()}
        </div>
        {renderDato()}
      </div>
    </div>
  );
};

export default PreguntasForm;
