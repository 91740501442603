import { createContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { tiposIdentificaciones } from '../../common/constants';

// Service
import { apiGetSeguros } from '../../services/apis';

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const location = useLocation();

  const [identidades, setIdentidades] = useState(tiposIdentificaciones);
  const [seguros, setSeguros] = useState([]);
  const [footer, setFooter] = useState(false);
  const [regionSelect, setRegionSelect] = useState('');
  const recaptchaRef = useRef(null);

  const resetearAppContext = () => {};

  useEffect(() => {
    const cargarDatos = async () => {
      const response = await apiGetSeguros();
      
      var { data, success } = response;
      if (success) {
        data= data.filter(seguro => seguro.activo).map(seguro => seguro);
        setSeguros(data);
      }
    };

    cargarDatos();
  }, []);

  //redireccionar a la pagina de la region seleccionada
  useEffect(() => {
    switch (regionSelect) {
      case 'CR':
        window.location.href = 'https://www.asegurame.cr/#/home';
        break;
      case 'HN':
        window.location.href = 'https://asegurame.hn/';
        break;
      default:
        break;
    }
  }, [regionSelect]);

  //verficar cada que cambiar las rutas para cambiar el estilo del footer
  useEffect(() => {
    const conditions = ['/cotizar', '/preguntas-frecuentes'];

    if (conditions.some(el => location.pathname.includes(el))) {
      setFooter(true);
    } else {
      resetearAppContext();
      setFooter(false);
    }
  }, [location]);

  return (
    <AppContext.Provider
      value={{
        identidades,
        setIdentidades,
        seguros,
        setSeguros,
        footer,
        setFooter,
        regionSelect,
        setRegionSelect,
        recaptchaRef,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
