import React, { useRef, useEffect } from 'react';

// Utils
import { convertToMarkdownBold } from '../../../core/utils';

const TextInputSalto = ({ texto = '', className = '', classDiv = '' }) => {
  const tituloRef = useRef(null);

  useEffect(() => {
    tituloRef.current.innerHTML = convertToMarkdownBold(texto, `${className}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classDiv}>
      <div className="input-salto-linea" ref={tituloRef}></div>
    </div>
  );
};

export default TextInputSalto;
