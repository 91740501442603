import React, { memo } from 'react';

// Components
import { InputAdornment, TextField } from '@mui/material';
import { TextCustom, IconButtonCustom, LoaderInput, MensajeError } from '../';

// Core
import {
  validTextInput,
  validInputInitialNumbers,
} from '../../../core/validations';

//assets
import { ReactComponent as IconoError } from '../../../assets/icons/Icono-Error.svg';
import { ReactComponent as IconoListo } from '../../../assets/icons/Icono-Listo.svg';

// Styles
import { colors } from '../../styles/theme';

const { general, black, white, ligthGray, error, green, bordes } = colors;

const TextInputCustom = ({
  name = '',
  value = '',
  textAlignPlaca = false,
  setValue = () => null,
  onBlur = () => null,
  onEnter = () => null,
  placeholder = '',
  type = 'text',
  typesValidation = '',
  validInitNumbers = [],
  maxLength = null,
  className = '',
  iconStart = null,
  iconMode = 'adornment',
  iconTypeColor = 'primary',
  iconOnClick = () => null,
  msgError = '',
  disabled = false,
  multiline = false,
  required = false,
  success = false,
  errorInput = false,
  fontSize = 18,
  textAlign = textAlignPlaca ? 'center' : 'left',
  borderStyle = false,
  onChange = () => null,
  onKeyUp = () => null,
  loader = false,
  index = '',
  nameValue = '',
  prefix = '',
  alignError = 'center',
  style = {},
  
}) => {
  const handleOnChange = e => {
    const inputValue = e.target.value;
    let isValid = true;
    if (validInitNumbers.length) {
      isValid = validInputInitialNumbers(inputValue, validInitNumbers);
    } else {
      isValid = validTextInput(inputValue, typesValidation);
    }
    if (isValid || inputValue === '' || !inputValue) {
      if (nameValue) setValue(inputValue, index, nameValue);
      else setValue(inputValue);

      onChange(e.target.value);
    }
  };

  const renderIcon = icon => {
    if (icon) {
      return iconMode === 'button' ? (
        <IconButtonCustom
          icon={icon}
          onClick={iconOnClick}
          typeColor={iconTypeColor}
        />
      ) : (
        <InputAdornment position="start">{icon}</InputAdornment>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <TextField
        label={name}
        value={value}
        onChange={handleOnChange}
        onBlur={onBlur}
        autoComplete='off'
        onKeyDown={event => {
          if (event.code === 'Enter') {
            onEnter();
          }
        }}
        onKeyUp={onKeyUp}
        variant="outlined"
        size="large"
        multiline={multiline}
        minRows={multiline ? '3' : '1'}
        maxRows={multiline ? '4' : '1'}
        placeholder={placeholder}
        type={type}
        disabled={loader ? true : disabled}
        required={required}
        inputProps={{
          maxLength: maxLength,
          className: 'text-base',
          style: {
            textAlign: textAlign,
            padding: '16px 14px',
            fontFamily: 'Poppins-SemiBold',
            borderRadius: 20,
            color:
              msgError.length > 0 || errorInput
                ? error
                : success
                ? green
                : general,
            letterSpacing: 0,
          },
        }}
        InputProps={{
          startAdornment: renderIcon(iconStart),
          endAdornment: loader ? (
            <LoaderInput />
          ) : prefix ? (
            <TextCustom text={prefix} className="color-general fontPSemiBold" />
          ) : msgError.length > 0 || errorInput ? (
            renderIcon(<IconoError className="mt-1" />)
          ) : (
            success && <IconoListo />
          ),
        }}
        sx={{
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: '#003763',
            backgroundColor: '#F2F2F2',
            borderRadius: 5,
          },
          '& .MuiInputBase-root.Mui-disabled': {
            '& fieldset': {
              borderRadius: 5,
              border: 1,

              borderColor: bordes,
            },
          },
          /* aqui se quita el borde al hacer hover */
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor:
                msgError.length > 0 || errorInput
                  ? error
                  : success
                  ? green
                  : borderStyle
                  ? bordes
                  : 'transparent',
            },
          },
          '& legend': {
            marginLeft: 2,
            fontSize: fontSize * 0.82,
          },
          '& .MuiInputBase-root': {
            '& fieldset': {
              borderRadius: 5,
              border:
                msgError.length > 0 || success || errorInput
                  ? 2
                  : borderStyle
                  ? 1
                  : 0,
              borderColor:
                msgError.length > 0 || errorInput
                  ? error
                  : success
                  ? green
                  : borderStyle
                  ? bordes
                  : 'transparent',
              color: general,
              // boxShadow: !borderStyle && 'var(--sombra-secondary)',
              boxShadow: borderStyle  ? 'var(--sombra-secondary)' : "none"
            },
            /* aqui se quita/agrega borde mientras haga foco  */
            '&.Mui-focused fieldset': {
              borderColor:
                msgError.length > 0 || errorInput
                  ? error
                  : success
                  ? green
                  : borderStyle
                  ? general
                  : 'transparent',
              color: black,
              fontSize: fontSize,
            },
          },
          '& .MuiInputLabel-asterisk': {
            color: error,
          },
          '& .MuiInputLabel-shrink': {
            marginLeft: 2,
            color: black,
            fontSize: fontSize,
            fontWeight: '600',
            '& .MuiInputLabel-asterisk': {
              color: error,
              display: 'inline',
            },
          },

          backgroundColor: disabled ? ligthGray : white,
          borderRadius: 5,
          marginTop: 1,
        }}
      />
      {msgError && <MensajeError mensaje={msgError} alignError={alignError} />}
    </div>
  );
};

export default memo(TextInputCustom);
