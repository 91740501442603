// Components
import { Link } from 'react-router-dom';
import { SelectCustom, TextCustom } from '../../atoms';

//assets
import logoFacebook from '../../../assets/icons/Footer/facebook-logo.svg';
import logoInstagram from '../../../assets/icons/Footer/instragram-logo.svg';
import logoLinkedin from '../../../assets/icons/Footer/lindekin-logo.svg';
import logoWhatsapp from '../../../assets/icons/Footer/whatsapp-logo.svg';
import { ReactComponent as IconHN } from '../../../assets/icons/Paises/Icono-Honduras.svg';
import { ReactComponent as IconCR } from '../../../assets/icons/Paises/Icono-CostaRica.svg';

//style
import './style.css';

//hooks
import { useApp } from '../../../hooks/others';

const Footer = () => {
  //context
  const { footer, regionSelect, setRegionSelect } = useApp();

  return (
    <>
      <footer className="bg-general ">
        {footer ? (
          <div className="container mx-auto py-5 px-7">
            <div className="copyright lg:flex lg:justify-between items-center lg:text-left">
              <div className="flex gap-3 flex-col lg:flex-row lg:items-center">
                <img
                  src="https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Logos/uniserse-logo-blanco.png"
                  alt="Logo Uniserse"
                  className="w-36  mx-auto flex items-center justify-center"
                />
                <div className="separador border-white"></div>

                <TextCustom
                  text="Regulado y Supervisado por SUGESE, Superintendencia General de Seguros de Costa Rica   |  Licencia: SC-12-115"
                  variant="p"
                  component="p"
                  className="text-white leading-6 text-[11px] lg:text-[12px] fontPRegular font-medium"
                />
              </div>
              <TextCustom
                text={`Todos los Derechos Reservados | © ${new Date().getFullYear()} Asegurame`}
                variant="p"
                component="p"
                className="text-white text-[11px] lg:text-[12px]  fontPRegular font-medium"
              />
            </div>
          </div>
        ) : (
          <div className="container mx-auto py-12 px-12">
            <div className="grid lg:grid-cols-5 mb-10 footer">
              <div>
                <img
                  src="https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Logos/uniserse-logo-blanco.png"
                  alt="Logo Uniserse"
                  className="w-56 mt-6 mx-auto mb-8"
                />
              </div>
              <div className="encuentranos">
                <TextCustom
                  text="Encuéntranos"
                  variant="h5"
                  component="h5"
                  className="color-primary text-sm fontPSemiBold mb-3 v-desktop-footer"
                />
                <TextCustom
                  text="Contáctanos"
                  variant="h5"
                  component="h5"
                  className="color-primary fontPRegular font-semibold text-xs my-3 v-movil-footer"
                />
                <a
                  href={
                    'https://www.google.com/maps/place/UNISERSE+CORREDURIA+DE+SEGUROS,+La+Uruca,+frente+costado+S.E.+del+puente+Juan+Pablo+II,+San+Jos%C3%A9,+Costa+Rica/@9.948955,-84.1117411,19z/data=!4m6!3m5!1s0x8fa0fb9de9a320dd:0xac47973d8452e10f!8m2!3d9.949369!4d-84.1113959!16s%2Fg%2F11hzsnss8q?hl=es-419'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="text-white text-xs fontPRegular font-normal mb-2 leading-5 md:mb-0">
                    Costado SurEste del Puente{' '}
                    <br className="hidden lg:block" /> Juan Pablo II, Edificio
                    UNISERSE. <br /> La Uruca, San José, Costa Rica
                  </p>
                </a>
              </div>
              <div>
                <TextCustom
                  text="Contacto"
                  variant="h5"
                  component="h5"
                  className="color-primary text-sm fontPSemiBold mb-3 v-desktop-footer"
                />
                <Link
                  to={'https://wa.me/50686473773'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white text-xs fontPRegular block mb-2 underline  lg:no-underline  hover:underline hover:font-semibold hover:scale-110 transition-all duration-200"
                >
                  (5O6) 8647-3773
                </Link>

                <Link
                  to={'mailto:info@asegurame.cr'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white text-xs fontPRegular block mb-2 underline lg:no-underline hover:underline hover:font-semibold hover:scale-110 transition-all duration-200"
                >
                  info@asegurame.cr
                </Link>
              </div>
              <hr className="w-full my-4 separador-v-movil-footer" />
              <div>
                <TextCustom
                  text="Más"
                  variant="h5"
                  component="h5"
                  className="color-primary text-sm fontPSemiBold mb-3"
                />
                <Link
                  to={'/blog'}
                  className="text-white text-xs fontPRegular block mb-2 hover:underline hover:font-semibold hover:scale-110 transition-all duration-200"
                >
                  Blog
                </Link>
                <Link
                  to={'/contactanos'}
                  className="text-white text-xs fontPRegular block mb-2 hover:underline hover:font-semibold hover:scale-110 transition-all duration-200"
                >
                  Contáctanos
                </Link>
                <Link
                  to={'/preguntas-frecuentes'}
                  className="text-white text-xs fontPRegular block mb-2 hover:underline hover:font-semibold hover:scale-110 transition-all duration-200"
                >
                  Preguntas Frecuentes
                </Link>
                <Link
                  to={'/terminos-condiciones'}
                  className="text-white text-xs fontPRegular block mb-2 hover:underline hover:font-semibold hover:scale-110 transition-all duration-200"
                >
                  Términos y Condiciones
                </Link>
              </div>
              <div className="grid gap-2">
                <div>
                  <TextCustom
                    text="Región"
                    variant="h5"
                    component="h5"
                    className="color-primary text-sm fontPSemiBold mb-2 xl:mb-3"
                  />
                  <SelectCustom
                    className="max-w-[220px] mx-auto"
                    borderStyle={true}
                    radiusBorder={4}
                    altura={'h-12'}
                    value={regionSelect ? regionSelect : 'CR'}
                    setValue={setRegionSelect}
                    defaultValue="CR"
                    options={[
                      {
                        id: 'HN',
                        label: 'Honduras',
                        icon: <IconHN className="max-h-[30px] max-w-[30px]" />,
                      },
                      {
                        id: 'CR',
                        label: 'Costa Rica',
                        icon: <IconCR className="max-h-[30px] max-w-[30px]" />,
                      },
                    ]}
                    onlyIconOrLabel={'ambos'}
                    bgTransparente={true}
                    overflow="hidden"
                    alinear="izquierda"
                  />
                </div>
                <div className="mt-5">
                  <TextCustom
                    text="Siguenos"
                    variant="h5"
                    component="h5"
                    className="color-primary text-sm fontPRegular font-semibold mb-3"
                  />
                  <div className="flex justify-center lg:justify-start gap-7">
                    <a
                      href={
                        'https://www.facebook.com/profile.php?id=100094186088673'
                      }
                      target="_blank"
                      className="hover:scale-125 transition-all duration-200"
                      rel="noopener noreferrer"
                    >
                      <img src={logoFacebook} alt="Logo de Facebook" />
                    </a>
                    <a
                      href={'https://www.instagram.com/asegurame.cr/'}
                      target="_blank"
                      className="hover:scale-125 transition-all duration-200"
                      rel="noopener noreferrer"
                    >
                      <img src={logoInstagram} alt="Logo de Instagram" />
                    </a>
                    <a
                      href={'https://www.linkedin.com/company/asegurame'}
                      target="_blank"
                      className="hover:scale-125 transition-all duration-200"
                      rel="noopener noreferrer"
                    >
                      <img src={logoLinkedin} alt="Logo de Linkedin" />
                    </a>
                    <a
                      href={'https://wa.me/50686473773'}
                      target="_blank"
                      className="hover:scale-125 transition-all duration-200"
                      rel="noopener noreferrer"
                    >
                      <img src={logoWhatsapp} alt="Logo de Whatsapp" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className=" mt-10 copyright lg:flex lg:justify-between">
              <p className="text-white fontPSemiBold text-[11px] lg:text-sm lg:text-left ">
                UNISERSE: Correduría de Seguros, Regulada y Supervisada por
                SUGESE, Superintendencia General de Seguros de Costa Rica |
                Licencia: SC-12-115
              </p>

              <p className="text-white text-[11px]  fontPRegular font-medium lg:text-sm lg:text-left">
                Todos los Derechos Reservados |{' '}
                <br className="min-[428px]:hidden" />©{' '}
                {new Date().getFullYear()} Asegurame
              </p>
            </div>
          </div>
        )}
      </footer>
    </>
  );
};

export default Footer;
