// Components
import { useState } from 'react';
import { ButtonCustom, CheckBoxCustom, TextCustom } from '../../atoms';
import { DialogCustom } from '../../templates';
import { DialogActions, DialogContent } from '@mui/material';

const ModalTerminos = ({
  open = false,
  setOpen = () => null,
  titulo = 'titulo por defecto',
  contenido = 'contenido por defecto',
  aceptacionModal = () => null,
}) => {
  const [acept, setAcept] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const handleAccept = () => {
    setOpen(false);
  };

  const handleBtn = () => {
    setBtnDisabled(acept);
    setAcept(!acept);
  };

  return (
    <DialogCustom open={open} setOpen={setOpen} title=" " disabledDismiss>
      <DialogContent className="pt-0 px-0  md:px-6 fontPRegular ">
        <div className="relative my-5 w-full max-h-[450px] max-w-[800px] text-xs lg:text-sm ">
          <div className="flex justify-center items-center w-full">
            <TextCustom
              text={titulo}
              className="text-center text-3xl text-general font-[Poppins-Bold] max-md:text-xl"
            />
          </div>
          <br />
          {contenido}

          {/* <RenderBoldText /> */}
          <div className="flex  justify-center w-full items-center">
            <CheckBoxCustom value={acept} setValue={handleBtn} />
            <TextCustom
              text="He leído y acepto todas las condiciones anteriores."
              className="sm:text-sm xsm:text-[15px] md:text-base"
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-center w-full mt-5 ">
          <ButtonCustom
            text="Aceptar"
            typeColor="primary"
            onClick={() => aceptacionModal(true)}
            width={'140px'}
            height={'45px'}
            disabled={btnDisabled}
          />
        </div>
      </DialogActions>
    </DialogCustom>
  );
};

export default ModalTerminos;
