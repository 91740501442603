const apiUsername = process.env.REACT_APP_apiUsername;
const apiPassword = process.env.REACT_APP_apiPassword;
const auth = 'Basic ' + window.btoa(apiUsername + ':' + apiPassword);

const buildRequest = (request = undefined) => {
  let headers;
  headers = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: auth,
    },
    params: request,
  };
  return headers;
};

export default buildRequest;
