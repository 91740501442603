//components
import { ButtonSortFilter, SelectCustom } from '../../atoms';

//context
import { useCotizador } from '../../../hooks/others';

const TituloFiltros = ({ sort = '', setSort = () => null, isFilterPlan = false }) => {
  //context
  const { dataCotizar } = useCotizador();

  const capitalizarPalabra = palabra => {
    if (palabra !== undefined) {
      return palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
    } else return '';
  };

  return (
    <div className="flex flex-row gap-2 px-2 lg:max-w-[1560px] lg:mx-auto justify-center md:justify-between text-center">
      <SelectCustom
        options={[
          { label: 'Plan Básico', id: 'plan-basico' },
          { label: 'Plan Plus', id: 'plan-plus' },
        ]}
        borderStyle={true}
        className={`${ isFilterPlan ? '' : 'hidden' } w-[50px] lg:w-[200px]`}
        placeholder="Plan"
        filtro={true}
        alinear='izquierda'
      />

      <div className={`${isFilterPlan ? 'hidden' : 'w-[50px] lg:w-[200px]'}`}>

      </div>

      <p className="fontPSemiBold color-general text-[19px] md:text-[40px] text-center">
        ¡Tu cotización está lista <br className="lg:hidden" />{' '}
        {capitalizarPalabra(dataCotizar?.datosPersonales?.primerNombre)}!
      </p>

      <div>
        <SelectCustom
          options={[
            { label: 'De menor a mayor', id: 'menor-a-mayor' },
            { label: 'De mayor a menor', id: 'mayor-a-menor' },
          ]}
          borderStyle={true}
          className="w-[50px] lg:w-[200px] hidden md:block"
          placeholder="Filtro"
          filtro={true}
          value={sort}
          setValue={setSort}
        />

        <ButtonSortFilter
          setValue={setSort}
          options={[
            { label: 'De menor a mayor', id: 'menor-a-mayor' },
            { label: 'De mayor a menor', id: 'mayor-a-menor' },
          ]}
          className="block md:hidden justify-center items-center"
        />
      </div>
    </div>
  );
};

export default TituloFiltros;
