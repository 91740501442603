import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Componentes
import { ButtonCustom } from '../../atoms';

// Utils
import { saltoLinea } from '../../../core/utils';

//styles
import './style.css';

const TitulosInformativa = ({
  titulo = '',
  descripcion = '',
  imagen = '',
  action = '',
  activeButton = false,
  urlBtn = '',
  tipo = {},
}) => {
  const navigate = useNavigate();

  const tituloRef = useRef(null);
  const descripcionRef = useRef(null);

  const handleClick = () => {
    navigate(urlBtn, { state: tipo });
  };

  useEffect(() => {
    tituloRef.current.innerHTML = saltoLinea(titulo);
    descripcionRef.current.innerHTML = saltoLinea(descripcion);
  }, [descripcion, titulo]);

  return (
    <div className="px-4 mt-24 mb-10 lg:mb-24 md:w-[85%] 2xl:w-[85%] mx-auto  ">
      <div className="bg-general rounded-[20px] flex flex-col justify-center items-center lg:flex-row-reverse lg:justify-around">
        <div
          className={`${
            ['SEG-V', 'SEG-ONC','SEG-VID'].includes(tipo.codigo)
              ? 'w-[60%] sm:w-[35%] lg:w-[30%] xl:max-w-64 '
              : 'w-[70%] sm:w-[50%] lg:w-[45%] xl:max-w-lg'
          } -translate-y-5`}
        >
          <img src={imagen} alt="Imagen principal" className="w-full h-full" />
        </div>
        <div className="text-white text-center mb-12 lg:text-left">
          <h1
            className="fontPSemiBold text-[22px] lg:mt-12 xl:text-[45px]"
            ref={tituloRef}
          >
            {titulo}
          </h1>
          <p
            className="fontPRegular text-[15px] mt-4 sm:text-base"
            ref={descripcionRef}
          >
            {descripcion}
          </p>
          {action}
          <ButtonCustom
            text="Comprar"
            className={`w-[120px] mt-6 lg:w-[160px] mx-auto lg:ml-0  ${
              activeButton ? 'block' : 'hidden'
            }`}
            typeColor="secondary"
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default TitulosInformativa;
