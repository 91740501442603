import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

// Componentes
import { MensajeError, SelectMultiCustom } from '../../atoms';

// Data
import { mesArray } from '../../../common/constants/index';

// Hooks
import { useCotizador } from '../../../hooks/others';

const SelectFechaNacimiento = ({
  errorAlert = false,
  value = null,
  setValue = () => null,
  msgError = '',
  alignError = '',
  isDisabled = false
}) => {
  const { dataCotizar, paso } = useCotizador();

  const [dia, setDia] = useState({ value: '', label: '' });
  const [mes, setMes] = useState({ value: '', label: '' });
  const [anio, setAnio] = useState({ value: '', label: '' });
  const [diaArray, setDiaArray] = useState([]);
  const [anioArray, setAnioArray] = useState([]);



  useEffect(() => {
    let dataDias = [];
    

    for (let x = 0; x < 31; x++) {
      dataDias.push({
        label: (x + 1).toString(),
        value: (x + 1).toString(),
      });
    }
    setDiaArray(dataDias);

    let anioActual = dayjs().year();
    let anioInicial = anioActual - 100;
    let dataAnio = [];

    if (dataCotizar.seguro.codigo === 'SEG-AEG') {
      for (let index = anioActual - 19; index >= anioInicial; index--) {
        dataAnio.push({
          value: index,
          label: index,
        });
      }
    } else {
      for (let index = anioActual; index > anioInicial; index--) {
        dataAnio.push({
          value: index,
          label: index,
        });
      }
    }

    setAnioArray(dataAnio);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paso === 1 || paso === 2 || paso === 4) {
      if(!value || value === "Invalid Date") {
        setDia({ value: '', label: '' })
        setMes({ value: '', label: ''})
        setAnio({ value: '', label: ''})
      }
      if (value?.includes('/')) {
        setDia({
          ...dia,
          value: value.split('/')[0],
          label: value.split('/')[0],
        });

        setAnio({
          ...anio,
          value: value.split('/')[2],
          label: value.split('/')[2],
        });

        const encontrarMes = mesArray.find(
          mes => mes.value === value.split('/')[1],
        );

        setMes(encontrarMes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paso, value]);

  useEffect(() => {
    if (
      !Object.values(dia).includes('') &&
      !Object.values(mes).includes('') &&
      !Object.values(anio).includes('')
    ) {
      setValue(
        dayjs(anio.value + '-' + mes.value + '-' + dia.value).format(
          'DD/MM/YYYY',
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dia, mes, anio]);

  const handleSetDia = val => {
    setDia(val);
  };

  const handleSetMes = val => {
    setMes(val);
  };

  const handleSetAnio = val => {
    setAnio(val);
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-2 mt-1.5">
        <SelectMultiCustom
          isMulti={false}
          errorAlert={errorAlert}
          options={diaArray}
          value={Object.values(dia).includes('') ? dia.label : dia}
          setValue={handleSetDia}
          placeholder="Dia"
          borderStyle={false}
          isDisabled = {isDisabled}
          
        />

        <SelectMultiCustom
          isMulti={false}
          errorAlert={errorAlert}
          options={mesArray}
          value={Object.values(mes).includes('') ? mes.label : mes}
          setValue={handleSetMes}
          placeholder="Mes"
          borderStyle={false}
          isDisabled = {isDisabled}
        />

        <SelectMultiCustom
          isMulti={false}
          errorAlert={errorAlert}
          options={anioArray}
          value={Object.values(anio).includes('') ? anio.label : anio}
          setValue={handleSetAnio}
          placeholder="Año"
          borderStyle={false}
          isDisabled = {isDisabled}
        />
      </div>
      {msgError && <MensajeError mensaje={msgError} alignError={alignError} />}
    </>
  );
};

export default SelectFechaNacimiento;
