import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import $ from 'jquery';

// Components
import CardResultadoMovil from '../CardResultadoMovil';

// Assets
import Next from '../../../assets/icons/IconLista.svg';

const ResultadoMovil = ({ data, coberturas, action, paso }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (paso === 3 && data.length > 0) {
      $('.owl-resultados .owl-prev span').prepend(
        `<img src="${Next}" style="transform: rotate(180deg);" alt="prev">`,
      );
      $('.owl-resultados .owl-next span').prepend(
        `<img src="${Next}" alt="next">`,
      );
      setInitialized(true);
    }
  }, [paso, data]);

  const options = {
    items: 2,
    loop: true,
    margin: 10,
    nav: true,
    navText: [
      `<img src="${Next}" style="transform: rotate(180deg);" alt="prev">`,
      `<img src="${Next}" alt="next">`,
    ],
    autoWidth: true,
    center: true,
  };

  const hasPlanInData = data => {
    return data.some(item => hasPlan(item));
  };

  const hasPlan = item => {
    return item.data !== undefined;
  };

  return (
    <div
      className="ancho-carrousel relative"
      style={{ minHeight: hasPlanInData(data) ? 'auto' : '300px' }}
    >
      {initialized && (
        <OwlCarousel
          className="owl-theme owl-resultados carousel-paso-resultados-mobile"
          {...options}
        >
          {data.map((plan, index) => (
            <CardResultadoMovil
              key={index}
              idPlan={plan._id}
              all={data}
              error={plan?.error}
              index={index}
              pasoSiguiente={action}
              plan={plan}
              coberturas={coberturas}
            />
          ))}
        </OwlCarousel>
      )}
    </div>
  );
};

export default ResultadoMovil;
