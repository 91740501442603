// Components
import { TextCustom } from '../../atoms';

const TextDetallePago = ({ titulo = '', detalle = '' }) => {
  return (
    <div>
      <TextCustom
        text={titulo}
        component="p"
        variant="p"
        className="fontPRegular  text-xs md:text-base"
      />
      <TextCustom
        text={detalle}
        className="fontPSemiBold text-xs md:text-base mt-1"
        component="p"
        variant="p"
      />
    </div>
  );
};

export default TextDetallePago;
