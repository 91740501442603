import React from 'react';

// Components
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextCustom } from '../';

import { ReactComponent as ArrowLeft } from '../../../assets/icons/Calendario/ArrowPrev.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/Calendario/ArrowNext.svg';
import { ReactComponent as IconoAgenda } from '../../../assets/icons/Calendario/Icono-Agenda.svg';

// Styles
import { colors } from '../../styles/theme';
import './styles.css';

import 'dayjs/locale/es';

const {
  black,
  white,
  ligthGray,
  primary,
  error,
  general,
  gray,
  disable,
  bordes,
} = colors;

const DatePickerCustom = ({
  name = '',
  value = '',
  setValue = () => null,
  required = false,
  disabled = false,
  minDate = undefined,
  maxDate = undefined,
  msgError = '',
  success = false,
  className = '',
  fontSize = 18,
  borderStyle = false,
  errorAlert = false,
  placeholderText = '',
}) => {
  const handleChange = inputValue => {
    setValue(inputValue);
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          label={name}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          className="w-100 date-range MuiDateCalendar-root"
          inputFormat="DD/MM/YYYY"
          format="DD/MM/YYYY"
          components={{
            OpenPickerIcon: IconoAgenda,
            LeftArrowIcon: ArrowLeft,
            RightArrowIcon: ArrowRight,
            SwitchViewIcon: 'a',
          }}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: placeholderText,
              }}
              placeholder={placeholderText}
              size="large"
              required={required}
              style={{ backgroundColor: disabled ? disable : white }}
              sx={{
                '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                  color: '#616464',
                  fontSize: '13px',
                },

                '& .MuiFormLabel-root': {
                  color: gray,
                  fontFamily: 'Poppins-Regular',
                },
                '& .MuiButtonBase-root .MuiSvgIcon-root': {},

                '& legend': {
                  marginLeft: 2,
                  fontSize: fontSize * 0.82,
                },
                '&.MuiPickersPopper-root': {
                  width: '360px',
                },
                '& .MuiInputBase-root': {
                  fontFamily: 'Poppins-Regular',
                  '& fieldset': {
                    borderRadius: 5,
                    border:
                      msgError.length > 0 || success || errorAlert
                        ? 2
                        : borderStyle
                        ? 1
                        : 0,
                    borderColor:
                      msgError.length > 0 || errorAlert
                        ? error
                        : success
                        ? general
                        : borderStyle
                        ? bordes
                        : 'transparent',
                    color: general,
                    boxShadow: !borderStyle && 'var(--sombra-secondary)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: primary,
                    color: black,
                    fontSize: fontSize,
                  },
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    border:
                      msgError.length > 0 || success || errorAlert
                        ? 2
                        : borderStyle
                        ? 1
                        : 0,
                    borderColor:
                      msgError.length > 0 || errorAlert
                        ? error
                        : success
                        ? general
                        : borderStyle
                        ? bordes
                        : 'transparent',
                  },
                },
                '& .MuiInputLabel-asterisk': {
                  display: 'none',
                },
                '& .MuiInputLabel-shrink': {
                  marginLeft: 2,
                  color: black,
                  fontSize: fontSize,
                  fontWeight: '600',
                  '& .MuiInputLabel-asterisk': {
                    // color: red,
                    display: 'inline',
                  },
                },
                backgroundColor: disabled ? ligthGray : white,
                borderRadius: 2,
                marginTop: 1,
              }}
            />
          )}
          disabled={disabled}
        />
      </LocalizationProvider>
      {msgError && (
        <div className="flex gap-2 items-center mt-2">
          <TextCustom
            text={msgError}
            className="text-xs fontPRegular color-error"
          />
        </div>
      )}
    </div>
  );
};

export default DatePickerCustom;
