import React from 'react';

// Styles
import './style.css';

const LoaderInput = () => {
  return <div className="loader-input"></div>;
};

export default LoaderInput;
