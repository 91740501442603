//components
import { useState } from 'react';

import {
  ButtonCustom,
  SelectCustom,
  TextInputCustom,
  TextCustom,
  AlertCardCustom,
} from '../../atoms';
import { TitulosInformativa } from '../../molecules';

//styles
import './style.css';

// API
import { apiCrearContactoComentario } from '../../../services/apis';

//context
import { useForm, useApp } from '../../../hooks/others';

// Validations
import { formValidContacto } from '../../../core/validations/formValidContacto';
import { validInputEmail } from '../../../core/validations';

import { typesValidation } from '../../../common/types';

const Contacto = () => {
  //assets
  const ContactoBg =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Contacto/Asegurame-Contacto.webp';
  const Correo =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Contacto/Asegurame-Correo.webp';
  const Telefono =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Contacto/Asegurame-Telefono.webp';
  const Ubicacion =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Contacto/Asegurame-Ubicacion.webp';

  const { seguros, recaptchaRef } = useApp();

  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [tipoSeguro, setTipoSeguro] = useState('');
  const [comentario, setComentario] = useState('');
  const [mensajeAlerta, setMensajeAlerta] = useState(false);

  const [formErrors, setFormErrors] = useForm({
    nombre: '',
    email: '',
    tipoSeguro: '',
    comentario: '',
  });

  //*funciones

  const handleSubmit = async () => {
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      if (handleValidForm()) {
        const params = {
          nombre,
          correo: email,
          seguro: tipoSeguro,
          comentario,
          tokenReCaptcha: token,
        };

        const { success } = await apiCrearContactoComentario(params);

        if (success) {
          setMensajeAlerta(true);

          setNombre('');
          setComentario('');
          setEmail('');
          setTipoSeguro('');

          setTimeout(() => {
            setMensajeAlerta(false);
          }, 5000);
        }
      }
    }
  };

  const handleValidForm = () => {
    const data = { nombre, email, tipoSeguro, comentario };
    const responseValid = formValidContacto(data);
    const { isValid, msgValid } = responseValid;
    setFormErrors(msgValid.errors);
    // setFormSuccess(msgValid.success);
    return isValid;
  };

  const handleForm = (value, event) => {
    if (formErrors[value] && event) {
      if (
        value === 'email' &&
        !validInputEmail(event, typesValidation.validateEmail)
      ) {
        setFormErrors({
          ...formErrors,
          [value]: 'Formato de correo electrónico no válido.',
        });
      } else {
        setFormErrors({ ...formErrors, [value]: '' });
      }
    }
  };

  return (
    <main style={{ backgroundColor: '#fafcfc' }} className="altura-pantalla">
      <TitulosInformativa
        titulo={`Mantengámonos \\n en contacto`}
        descripcion={`¡Estamos aquí para ayudarte! \\n Comunicate con nosotros si necesitas ayuda`}
        imagen={ContactoBg}
        className="contacto-imagen-texto"
        classNameText="contenido-contacto"
        classNameImg="contacto-img"
      />
      <section className="px-4 contacto-tarjetas contenedor-card lg:mt-28 lg:px-28 xl:px-48">
        <div className="flex-tarjetas-cards grid-tarjetas-contacto ">
          <div className="card fontPSemiBold">
            <img src={Ubicacion} alt="Ubicacion" />
            <div className="card-contenido my-3 h-[70px] ">
              <a
                href={
                  'https://www.google.com/maps/place/UNISERSE+CORREDURIA+DE+SEGUROS,+La+Uruca,+frente+costado+S.E.+del+puente+Juan+Pablo+II,+San+Jos%C3%A9,+Costa+Rica/@9.948955,-84.1117411,19z/data=!4m6!3m5!1s0x8fa0fb9de9a320dd:0xac47973d8452e10f!8m2!3d9.949369!4d-84.1113959!16s%2Fg%2F11hzsnss8q?hl=es-419'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="fontPRegular underline text-[#222222]">
                  Costado SurEste del Puente Juan Pablo II, Edificio UNISERSE,
                  La Uruca, San José, Costa Rica
                </p>
              </a>
            </div>
          </div>
          <div className="card fontPSemiBold">
            <img src={Telefono} alt="Telefono" />
            <div className="card-contenido my-3 h-[70px]">
              <a
                href={'https://wa.me/50686473773'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="fontPRegular underline text-[#222222]">
                  (506) 8647-3773
                </p>
              </a>
              <a
                href={'tel:50625194500'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="fontPRegular underline text-[#222222]">
                  (506) 2519-4500
                </p>
              </a>
              <a
                href={'tel:50625194555'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="fontPRegular underline text-[#222222]">
                  (506) 2519-4555
                </p>
              </a>
            </div>
          </div>
          <div className="card fontPSemiBold">
            <img src={Correo} alt="Correo" />
            <div className="card-contenido my-3 h-[70px] ">
              <a
                href={'mailto:info@asegurame.cr'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="fontPRegular underline text-[#222222]">
                  info@asegurame.cr
                </p>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="px-4 lg:mt-28">
        <div className="contenido-formulario">
          <p className="fontPSemiBold  color-general">
            Mantengámonos en contacto
          </p>

          <div className="centrar-formulario">
            <form className="formulario-contacto">
              <div className="campo">
                <TextCustom text="Nombre Completo:" required={true} />
                <TextInputCustom
                  placeholder="Ingresa tu nombre."
                  value={nombre}
                  setValue={setNombre}
                  msgError={formErrors.nombre}
                  typesValidation={typesValidation.lettersExtend}
                  onKeyUp={event => handleForm('nombre', event.target.value)}
                />
              </div>
              <div className="campo">
                <TextCustom text="Correo electrónico:" required={true} />
                <TextInputCustom
                  placeholder="Ingresa tu correo electrónico."
                  type="email"
                  value={email}
                  setValue={setEmail}
                  msgError={formErrors.email}
                  onKeyUp={event => handleForm('email', event.target.value)}
                />
              </div>
              <div className="campo">
                <TextCustom text="Tipo de seguro:" required={true} />
                <SelectCustom
                  className="fontPSemiBold"
                  placeholder="Selecciona el tipo de seguro"
                  options={seguros.map(seguro => ({
                    id: seguro.codigo,
                    label: seguro.nombre,
                  }))}
                  value={tipoSeguro}
                  setValue={setTipoSeguro}
                  msgError={formErrors.tipoSeguro}
                  alinear="izquierda"
                  changeValid={handleForm}
                  nameValue="tipoSeguro"
                />
              </div>
              <div className="campo mb-14">
                <TextCustom text="Comentario:" required={true} />
                <TextInputCustom
                  placeholder="Déjanos tus comentarios, preguntas o sugerencias aquí."
                  multiline={true}
                  value={comentario}
                  setValue={setComentario}
                  msgError={formErrors.comentario}
                  onKeyUp={event =>
                    handleForm('comentario', event.target.value)
                  }
                />
              </div>
              <div className="centrar-btn">
                <ButtonCustom
                  onClick={handleSubmit}
                  text="Enviar"
                  typeColor="primary"
                  width="200px"
                  className="mb-14"
                />
              </div>
            </form>
          </div>
        </div>
        <AlertCardCustom show={mensajeAlerta} className="mb-10" />
      </section>
    </main>
  );
};

export default Contacto;
