export const typesValidation = {
  onlyNumber: 'onlyNumber',
  onlyLetters: 'onlyLetters',
  onlyLettersExtend: 'onlyLettersExtend',
  onlyAlphanumeric: 'onlyLaphanumeric',
  onlyAlphanumericExtend: 'onlyLaphanumericExtend',
  validateEmail: 'validateEmail',
  validateEmailDomain: 'validateEmailDomain',
  multiLettersExtend: 'multiLettersExtend',
  lettersExtend: 'lettersExtend',
};
