// Components
import { NavLink, useLocation } from 'react-router-dom';
import { AppBar, Toolbar } from '@mui/material';
import { ButtonCustom, SelectCustom, TextCustom } from '../../atoms';
import { MobileMenu } from '../../organisms';
import { DropMenuOptions } from '../../molecules';

// Assets
import Logo from '../../../assets/images/Logos/AsegurameLogo.svg';
import menuHamburger from '../../../assets/icons/menu-hamburger.svg';
import { ReactComponent as IconHN } from '../../../assets/icons/Paises/Icono-Honduras.svg';
import { ReactComponent as IconCR } from '../../../assets/icons/Paises/Icono-CostaRica.svg';

//hooks
import { useApp } from '../../../hooks/others';

const HeaderMenu = () => {
  const { regionSelect, setRegionSelect } = useApp();

  const { pathname } = useLocation();

  /* eventos para monstar y ocular el menu despegable */
  const handleMouseIn = () => {
    const menuDespegable = document.querySelector('.menu-despegable');
    menuDespegable.classList.add('show');
  };
  const handleMouseOut = () => {
    const menuDespegable = document.querySelector('.menu-despegable');
    menuDespegable.classList.remove('show');
  };

  const handleOpenMenu = () => {
    const menu = document.querySelector('#mobile-menu');
    menu.classList.add('active');
  };

  const handelCloseMenu = () => {
    const menu = document.querySelector('#mobile-menu');
    menu.classList.remove('active');
  };

  return (
    <AppBar
      position="fixed"
      elevation={2}
      sx={{
        backgroundColor: 'var(--color-white)',
        boxShadow: '0px 2px 5px #00000029',
      }}
    >
      <Toolbar className="bg-sombra-header">
        <MobileMenu handelCloseMenu={handelCloseMenu} />

        <div className="w-full  ">
          <div className="nav-padding contenedor-logo my-6 lg:my-0 ">
            <NavLink to={'/home'} onMouseEnter={handleMouseOut}>
              <img
                src={Logo}
                alt="avatar"
                className="w-40 h-9 md:w-48 md:h-auto"
              />
            </NavLink>

            <div className="block lg:hidden">
              <img
                src={menuHamburger}
                alt="Menu hamburguesa"
                onClick={handleOpenMenu}
              />
            </div>

            <div className="v-web items-center ">
              <div className="flex items-center gap-8 h-16">
                <div onMouseOver={handleMouseIn}>
                  <TextCustom
                    text="Seguros"
                    className={`menu-despegable-show text-sm enlace-header fontPRegular cursor-pointer text-black`}
                  />
                </div>
                <NavLink to="/conocenos" onMouseEnter={handleMouseOut}>
                  <TextCustom
                    text="Conócenos"
                    className={`enlace-header text-sm fontPRegular menu-despegable-show cursor-pointer  text-black ${
                      pathname === '/conocenos' && 'font-bold'
                    }`}
                  />
                </NavLink>
                <div
                  className="menu-despegable w-[90%]  mx-auto text-4xl p-4 pt-2 text-[#666666] mt-5 xl:w-[80%] 2xl:w-[95%]"
                  onMouseLeave={handleMouseOut}
                >
                  <div onMouseLeave={handleMouseOut}>
                    <DropMenuOptions />
                  </div>
                </div>
                <NavLink to="/blog" onMouseEnter={handleMouseOut}>
                  <TextCustom
                    text="Blog"
                    className={`text-sm enlace-header fontPRegular cursor-pointer text-black ${
                      pathname === '/blog' && 'font-bold'
                    }`}
                  />
                </NavLink>
                <NavLink to="/cotizar" onMouseEnter={handleMouseOut}>
                  <ButtonCustom
                    text="Cotizar"
                    className="text-sm mt-1"
                    height="auto"
                    width="142px"
                  />
                </NavLink>
                <div className="select-pais-nav" onMouseEnter={handleMouseOut}>
                  <SelectCustom
                    borderStyle={true}
                    className="max-w-[110px] "
                    radiusBorder={2}
                    value={regionSelect ? regionSelect : 'CR'}
                    setValue={setRegionSelect}
                    defaultValue="CR"
                    options={[
                      {
                        id: 'HN',
                        label: 'Honduras',
                        icon: <IconHN className="max-h-[28px] max-w-[28px]" />,
                      },
                      {
                        id: 'CR',
                        label: 'Costa Rica',
                        icon: <IconCR className="max-h-[28px] max-w-[28px]" />,
                      },
                    ]}
                    onlyIconOrLabel={'icon'}
                    altura={'h-10'}
                    overflow="hidden"
                  />
                </div>
                <NavLink
                  to="/preguntas-frecuentes"
                  className="faq color-primary p-1 w-9 h-9 text-center rounded-full borde-fq mt-1"
                  onMouseEnter={handleMouseOut}
                >
                  <TextCustom
                    text="?"
                    className="p color-primary text-center font-bold text-base fontPRegular "
                  />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderMenu;
