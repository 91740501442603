import { useState } from 'react';

// Components
import { ButtonCustom, CheckBoxCustom, TextCustom } from '../../atoms';
import { DialogCustom } from '../../templates';
import { DialogActions, DialogContent } from '@mui/material';

const ModalOncologico = ({
  open = false,
  setOpen = () => null,
  aceptacionModal = () => null,
  /* contenido = 'contenido por defecto', */
}) => {
  const [acept, setAcept] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleBtn = () => {
    setBtnDisabled(acept);
    setAcept(!acept);
  };

  return (
    <DialogCustom
      open={open}
      setOpen={setOpen}
      title={'Declaración de Padecimientos'}
      disabledDismiss
    >
      <DialogContent className="pt-0 px-0 md:px-6 fontPRegular">
        <div className="relative my-5 w-auto  max-h-[338px] max-w-[800px] text-xs lg:text-sm text-justify">
          {/* <p className="font-bold">
           Declaración de Padecimientos
          </p> */}
          <p>
            {`Declaro que no padezco ni he padecido de 
            cáncer o tumor maligno, además 
            no estoy siendo estudiado ni esperando resultados para descubrir o 
            descartar un posible tumor maligno o cualquier otra manifestación 
            sugestiva de cáncer.`}
          </p>

          <div className="flex justify-center w-full items-center ">
            <CheckBoxCustom value={acept} setValue={handleBtn} />
            <TextCustom
              text="He leído y acepto todas las condiciones anteriores."
              className="sm:text-sm xsm:text-[15px] md:text-base"
            />
          </div>
          {/* {contenido} */}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-center w-full pb-6">
          <ButtonCustom
            text="Contactame"
            typeColor="danger"
            onClick={() => {
              aceptacionModal(false);
            }}
            width={'150px'}
            height={'45px'}
            className="mr-5 text-xs sm:text-sm xsm:text-[15px] md:text-base"
          />
          <ButtonCustom
            text="Aceptar"
            typeColor="primary"
            onClick={() => aceptacionModal(true)}
            width={'150px'}
            height={'45px'}
            disabled={btnDisabled}
            className="text-xs sm:text-sm xsm:text-[15px] md:text-base"
          />
        </div>
      </DialogActions>
    </DialogCustom>
  );
};

export default ModalOncologico;
