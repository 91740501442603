export const typesEndpoint = {
  getSeguros: 'categorias',
  getCatalogo: 'cotizador/productos/catalogo',
  getIdentidades: 'cotizador/identidades',
  personas: 'cotizador/persona',
  postCotizar: 'cotizador/cotizar',
  postPlan: 'cotizador/plan',
  postEmitirPoliza: 'cotizador/pago',
  postDataCotizador: 'cotizador/crm',
  postVerificarDataCotizador: 'cotizador/verificar-crm',
  postActualizarOportunidad: 'oportunidad/crm',
  getPersonaCredID: 'get-persona-credid',
  getInfoVehiculoCredID: 'get-info-vehiculo',
  emitirPoliza: 'cotizador/emision-poliza',
  realizarPago: 'cotizador/pago',
  resumenCorreo: 'correos',
  almacenarPagoOPoliza: 'oportunidades/poliza-pago',
  getCotizaciones: 'cotizaciones/cotizador',
  blog: 'blog',
  contactanos: 'contactanos',
  recuperarCotizacion: 'cotizador/recuperar-cotizacion',
  actualizarDataViaje: 'cotizar/crm-viaje',
  encontrarPersonaListaControl : 'lista-negra/encontrar-persona-blacklist',
  postCrearVehiculo : 'crear-vehiculo',
  postRiesgoAsegurado: 'riesgo-asegurado',
  crearBitacora : 'cotizador/crear-bitacora',
  obtenerEtapa : 'obtener-etapa',
  correoVerificacion: 'cotizador/correo-verificacion',
  correoFacturacion: 'cotizador/correo-facturacion'
};
