import axios from 'axios';
import buildRequest from '../buildRequest';
import { typesEndpoint } from '../../../common/types';




export const apiEncontrarPersonaListaControl = async params => {
    const url = `${process.env.REACT_APP_api}${typesEndpoint.encontrarPersonaListaControl}`;
    const method = 'post';
    const dataResponse = {
      success: false,
      statusCode: 0,
      message: '',
      data: [],
    };
    const request = {
     ...params
    };
  
    try {
      const response = await axios[method](url, request, buildRequest());
      const { status, data } = response;
      dataResponse.success = true;
      dataResponse.data = data.resp;
      dataResponse.statusCode = status;
    } catch (error) {
      dataResponse.data = error;
      dataResponse.statusCode = error.response?.status;
    }
    return dataResponse;
  };
  