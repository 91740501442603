import { useEffect, useRef } from 'react';

//Componentes
import { TextCustom } from '../../atoms';

// Utils
import { saltoLinea } from '../../../core/utils';

const TitulosCotizador = ({ codigo = '', descripcion = '', color = '' }) => {
  const subtituloRef = useRef(null);

  const renderTitulo = {
    'SEG-DA': {
      renderTitulo: (
        <h2
          className={`fontPBold color-general mb-2 leading-10 sm:leading-[3.5rem] ${color}`}
        >
          Seguro de <br /> Deducible de <br /> Automóvil (¢)
        </h2>
      ),
    },
    'SEG-V': {
      renderTitulo: (
        <h2
          className={`fontPBold color-general mb-2 leading-10 sm:leading-[3.5rem] ${color}`}
        >
          Seguro de <br /> Viaje ($)
        </h2>
      ),
    },
    'SEG-AEG': {
      renderTitulo: (
        <h2
          className={`fontPBold color-general mb-2 leading-9 sm:leading-[3.5rem] ${color}`}
        >
          Seguro de <br /> Enfermedades <br /> Graves (¢)
        </h2>
      ),
    },
    'SEG-ONC': {
      renderTitulo: (
        <h2
          className={`fontPBold color-general mb-2 leading-9 sm:leading-[3.5rem] ${color}`}
        >
          Seguro <br /> Oncológico Plus (¢)
        </h2>
      ),
    },
    'SEG-VID': {
      renderTitulo: (
        <h2
          className={`fontPBold color-general mb-2 leading-9 sm:leading-[3.5rem] ${color}`}
        >
          Seguro de<br /> Vida Plus (¢)
        </h2>
      ),
    },
  };

  useEffect(() => {
    subtituloRef.current.innerHTML = saltoLinea(descripcion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <TextCustom
        text="Compra en línea"
        variant="p"
        component="p"
        className="color-primary fontPSemiBold"
      />
      {renderTitulo[codigo]?.renderTitulo}
      <p
        ref={subtituloRef}
        className={`text-descripcion-seg fontPRegular color-gray ${color}`}
      >
        {descripcion}
      </p>
    </div>
  );
};

export default TitulosCotizador;
