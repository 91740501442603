//secciones - components
import {
  SeccionPrincipalHome,
  SeccionEnfoque,
  SeccionComoFunciona,
  SeccionPersonal,
  SeccionComparaSeguros,
  SeccionAseguradoras,
} from '../../organisms';

// Styles
import './style.css';

const Home = () => {
  return (
    <main>
      <SeccionPrincipalHome />
      <SeccionComoFunciona />
      <SeccionEnfoque />
      <SeccionPersonal svgFigura />
      <SeccionAseguradoras />
      <SeccionComparaSeguros />
    </main>
  );
};

export default Home;
