import React from 'react';

//components
import { TextCustom } from '../../atoms';

// Style
import './style.css';
const CardsPersonal = () => {
  //assets
  const Douglas =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Corredores/nuevo/DouglasS.webp';
  const Esteban =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Corredores/nuevo/EstebanM.webp';
  const Federico =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Corredores/FedericoP.webp';
  const Guillermo =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Corredores/nuevo/GuillermoG.webp';
  const Jerry =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Corredores/nuevo/JerryM.webp';
  const Johnny =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Corredores/nuevo/JohnnyP.webp';
  const Juan =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Corredores/nuevo/JuanPabloA.webp';
  const AndreaB =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Corredores/nuevo/AndreaB.webp';
  const Mauricio =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Corredores/nuevo/MauricioB.webp';
  const Gerardo =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Corredores/nuevo/Gerardo.webp';

  const personal = [
    {
      nombre: 'Mauricio Benavides',
      puesto: 'Asesor de Asegurame',
      licencia: 'Licencia: 12-1135',
      img: Mauricio,
    },
    {
      nombre: 'Andrea Benavides',
      puesto: 'Asesor de Asegurame',
      licencia: 'Licencia: 12-1136',
      img: AndreaB,
    },
    {
      nombre: 'Juan Pablo Acuña',
      puesto: 'Asesor de Asegurame',
      licencia: 'Licencia: 17-1503',
      img: Juan,
    },
    {
      nombre: 'Jerry Mendez',
      puesto: 'Asesor de Asegurame',
      licencia: 'Licencia: 16-1445',
      img: Jerry,
    },
    {
      nombre: 'Guillermo Galán',
      puesto: 'Asesor de Asegurame',
      licencia: 'Licencia: 16-1432',
      img: Guillermo,
    },
    {
      nombre: 'Douglas Solano',
      puesto: 'Asesor de Asegurame',
      licencia: 'Licencia: 12-1138',
      img: Douglas,
    },
    {
      nombre: 'Johnny Pacheco',
      puesto: 'Asesor de Asegurame',
      licencia: 'Licencia: 12-1140',
      img: Johnny,
    },
    {
      nombre: 'Federico Pacheco',
      puesto: 'Asesor de Asegurame',
      licencia: 'Licencia: 12-1139',
      img: Federico,
    },
    {
      nombre: 'Esteban Morales',
      puesto: 'Asesor de Asegurame',
      licencia: 'Licencia: 12-1137',
      img: Esteban,
    },
    {
      nombre: 'Gerardo Méndez',
      puesto: 'Asesor de Asegurame',
      licencia: 'Licencia: 24-2160',
      img: Gerardo,
    },
  ];

  return (
    <div className="contenedor-corredores gap-5 mx-auto justify-items-center justify-center 2xl:max-w-[1500px]">
      {personal.map(persona => (
        <div
          key={persona.nombre}
          className="hover:scale-110 transition-all duration-300 max-w-[18rem] xxlg:max-w-[14rem] 2xl:max-w-xs"
        >
          <img
            src={persona.img}
            className="rounded-[60px] inline-block w-[280px]"
            alt="Nuestro Personal"
          />
          <div className="text-center mt-2 flex flex-col">
            <TextCustom
              text={persona.nombre}
              variant="p"
              component="p"
              className="color-general fontPSemiBold text-base mt-4 nombre-persona"
            />
            <TextCustom
              text={persona.puesto}
              variant="p"
              component="p"
              className="color-gray-secondary fontPSemiBold text-sm"
            />
            <TextCustom
              text={persona.licencia}
              variant="p"
              component="p"
              className="color-gray-secondary fontPRegular text-sm"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardsPersonal;
