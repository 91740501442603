import React, { useEffect, useRef, useState } from 'react';

// Components
import { ButtonCustom, CheckBoxCustom, TextCustom } from '../../atoms';
import { DialogCustom } from '../../templates';
import { DialogActions, DialogContent } from '@mui/material';

const ModalCargoAutomatico = ({
  open = false,
  setOpen = () => null,
  titulo = '',
  contenido = '',
  aceptacionModal = () => null,
}) => {
  const contenidoRef = useRef(null);
  const [acept, setAcept] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    //if (open) {
    //  let newTexto = contenido.replace(/#/g, '<br>');
    //  let allTexto = newTexto.replace(/\*(.*?)\*/g, '<b>$1</b>');
    //  contenidoRef.current.innerHTML = allTexto;
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBtn = () => {
    setBtnDisabled(acept);
    setAcept(!acept);
  };

  return (
    <DialogCustom open={open} setOpen={setOpen} title={titulo} disabledDismiss>
      <DialogContent className="pt-0 px-0  md:px-6 fontPRegular">
        <div className="relative my-5 w-auto lg:w-[700px]" ref={contenidoRef}>
          {contenido}
        </div>
        <div className="flex justify-center w-full items-center">
          <CheckBoxCustom
            value={acept}
            setValue={handleBtn}
            typeColor="general"
          />

          <TextCustom text="Si, acepto" />
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-center w-full">
          <ButtonCustom
            text="Aceptar"
            typeColor="primary"
            onClick={() => aceptacionModal(true)}
            width={'130px'}
            height={'45px'}
            disabled={btnDisabled}
          />
        </div>
      </DialogActions>
    </DialogCustom>
  );
};

export default ModalCargoAutomatico;
