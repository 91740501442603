import axios from 'axios';
import buildRequest from '../buildRequest';
import { typesEndpoint } from '../../../common/types';

const apiUsername = process.env.REACT_APP_apiUsername;
const apiPassword = process.env.REACT_APP_apiPassword;
const auth = 'Basic ' + window.btoa(apiUsername + ':' + apiPassword);

export const apiGetPersona = async params => {
  const url = `${process.env.REACT_APP_api}${typesEndpoint.getPersonaCredID}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const request = {
    cedula: params.identificacion,
    tipo: params.tipo,
  };

  try {
    const response = await axios[method](url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: auth,
      },
      params: request,
      signal: AbortSignal.timeout(12000),
    });
    const { status, data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.statusCode = status;
  } catch (error) {
    dataResponse.data = error.response?.data;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
};
export const apiGetInfoVehiculo = async params => {
  const url = `${process.env.REACT_APP_api}${typesEndpoint.getInfoVehiculoCredID}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
    cotiza : true
  };

  const request = {
    placa: params.placa,
    clase:params.clase
  };

  try {
    const response = await axios[method](url, buildRequest(request));
    const { status, data} = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.cotiza = data?.cotiza
    dataResponse.statusCode = status;
  } catch (error) {
    dataResponse.data = error;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
};
