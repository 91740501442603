import React, { useState } from 'react';

// Components
import { Menu, MenuItem } from '@mui/material';

import { ReactComponent as SortIcon } from '../../../assets/icons/tablas/Icono-Filtro.svg';

const ButtonSortFilter = ({
  options = [],
  setValue = () => null,
  className = '',
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = value => {
    setValue(value);
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <button
        onClick={handleOpenMenu}
        className="w-[35px] h-[35px] border-[#003763] border flex justify-center items-center rounded-[10px] bg-white relative"
      >
        <SortIcon className="w-[16px] h-[15px]" />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleCloseMenu(option?.id)}>
            {option?.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ButtonSortFilter;
