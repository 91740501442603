import { typesEndpoint } from "../../../common/types";
import buildRequest from '../buildRequest';
import axios from 'axios';




export const apiPostVehiculo = async params => {
    const url = `${process.env.REACT_APP_api}${typesEndpoint.postCrearVehiculo}`;
    const method = 'post';
    const dataResponse = {
      success: false,
      statusCode: 0,
      message: '',
      data: [],
    };
  
    const request = {
      placa: params.placa,
      marca: params?.marca,
      modelo: params?.modelo,
      anio: params?.anio,
      carroceria: params?.carroceria,
      clase: params.clase,
      propietario: params.propietario
    };
  
    try {
      const response = await axios[method](url, request, buildRequest());
      const { status, data } = response;
  
      dataResponse.success = true;
      dataResponse.data = data.data;
      dataResponse.statusCode = status;
    } catch (error) {
      dataResponse.data = error;
      dataResponse.statusCode = error.response?.status;
    }
    return dataResponse;
  };