import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const CardAccordion = ({
  options = [],
  titleVisible = true,
  location = '',
}) => {
  const [rotarBoton, setRotarBoton] = useState({});

  // Assets
  const IconoLista =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/FAQ/Icono-VerMas.svg';
  const IconoVerMenos =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/FAQ/Icono-VerMenos.svg';

  useEffect(() => {
    if (location) {
      const btn = document.getElementById(location);
      btn.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
        inline: 'center',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let state = {};
    options.forEach(seccion => {
      seccion.datos.forEach(item => {
        const pregunta = item.pregunta;
        const estado = item.estado;
        state[pregunta] = estado;
      });
    });
    setRotarBoton(state);
  }, [options]);

  const handleChange = (e, pregunta) => {
    setRotarBoton({
      ...rotarBoton,
      [pregunta]: !rotarBoton[pregunta],
    });
  };

  return (
    <div>
      {options.map(option => (
        <div className="pt-5" key={option.titulo} id={option.codigo}>
          <h3
            className={`${
              titleVisible ? 'block' : 'hidden'
            } mt-10 mb-10 color-general text-center fontPSemiBold text-xl md:text-2xl lg:text-[30px] `}
          >
            {option.titulo}
          </h3>
          {option.datos.map(pr => (
            <Accordion
              className="bg-card-fq rounded-[20px] mb-5"
              sx={{
                '&:before': {
                  display: 'none',
                },
              }}
              key={pr.pregunta}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={e => handleChange(e, pr.pregunta)}
              >
                <Typography className="fontPSemiBold color-general text-sm md:text-base flex justify-between w-full">
                  {pr.pregunta}
                  <img
                    src={` ${
                      rotarBoton[pr.pregunta] === false
                        ? IconoLista
                        : IconoVerMenos
                    }`}
                    alt=""
                    className={`icono-lista`}
                  />
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                className="md:w-11/12"
                style={{ padding: '0px 16px 16px' }}
              >
                <Typography className="fontPRegular text-sm md:text-base">
                  {pr.respuesta}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CardAccordion;
