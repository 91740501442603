import React from 'react';

// Styles
import './style.css';

//assets
import { ReactComponent as IconoError } from '../../../assets/icons/Loader/Cotizar.svg';

const LoaderCotizar = ({ type = 'screen' }) => {
  return (
    <div
      className={`${
        type === 'screen' ? 'h-screen loading w-screen' : 'h-full'
      } `}
    >
      <div
        id="contenedor"
        className={`${type === 'screen' && 'h-screen contenedor-loader'}`}
      >
        <IconoError />
      </div>
    </div>
  );
};

export default LoaderCotizar;
