import { validInputEmail } from './validateInput';
import { typesValidation } from '../../common/types';

const { validateEmail } = typesValidation;

export const formValidContacto = data => {
  const dataResponse = {
    isValid: true,
    msgValid: {
      errors: null,
      success: null,
    },
  };
  let isValid = true;
  let inputsError = {
    nombre: '',
    email: '',
    tipoSeguro: '',
    comentario: '',
  };
  let inputsSuccess = {
    nombre: true,
    email: true,
    tipoSeguro: true,
    comentario: true,
  };

  if (!data.nombre) {
    inputsError.nombre = 'Tu nombre es requerido.';
    inputsSuccess.nombre = false;
    isValid = false;
  }

  if (!data.email) {
    inputsError.email = 'Tu correo electrónico es requerido.';
    inputsSuccess.email = false;
    isValid = false;
  }

  if (!inputsError.email && !validInputEmail(data.email, validateEmail)) {
    inputsError.email = 'Formato de correo electrónico no válido.';
    inputsSuccess.email = false;
    isValid = false;
  }

  if (!data.tipoSeguro) {
    inputsError.tipoSeguro = 'Selecciona un tipo de seguro.';
    inputsSuccess.tipoSeguro = false;
    isValid = false;
  }

  if (!data.comentario) {
    inputsError.comentario = 'Escribe un comentario, pregunta o sugerencia.';
    inputsSuccess.comentario = false;
    isValid = false;
  }

  dataResponse.isValid = isValid;
  dataResponse.msgValid.errors = inputsError;
  dataResponse.msgValid.success = inputsSuccess;
  return dataResponse;
};
