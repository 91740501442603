import React from 'react';
import Typography from '@mui/material/Typography';

const TextCustom = ({
  text = '',
  isParagraph = false,
  variant = '',
  isWrap = false,
  component = '',
  className = '',
  required = false,
}) => {
  return (
    <Typography
      paragraph={isParagraph}
      noWrap={isWrap}
      variant={variant}
      component={component}
      className={className ? className : `fontPRegular color-gray`}
    >
      {text} {required && <span className="text-[#DD2254]">*</span>}
    </Typography>
  );
};

export default TextCustom;
