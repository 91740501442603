import { useRef, useEffect } from 'react';

// Components
import { CardSeguroTitulo } from '../../molecules';

// Utils
import { convertToMarkdownBold } from '../../../core/utils';

const TitulosTexto = ({
  titulo = '',
  subtitulo = '',
  btn = null,
  className = '',
  subtituloPrincipal = false,
  mq = '',
  showSeguros = false,
}) => {
  const tituloRef = useRef(null);
  const subtituloRef = useRef(null);

  useEffect(() => {
    tituloRef.current.innerHTML = convertToMarkdownBold(
      titulo,
      `fontPSemiBold color-general ${className} md:${mq}`,
    );
    subtituloRef.current.innerHTML = convertToMarkdownBold(
      subtitulo,
      `color-gray fontPRegular text-xl font-normal ${
        subtituloPrincipal && 'text-2xl subtitulo'
      }`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="titulo-home" ref={tituloRef}></div>
      <div className="my-5 descripcion-home" ref={subtituloRef}></div>
      {btn ? btn : showSeguros ? <CardSeguroTitulo /> : null}
    </>
  );
};

export default TitulosTexto;
