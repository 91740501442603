import { useNavigate } from 'react-router-dom';

//Components
import { ButtonCustom, TextCustom } from '../../atoms';

// Constante
import { rutasCotizar } from '../../../common/constants';

const CardCotizacion = ({ id = '', seguro = '', data = '' }) => {
  const navigate = useNavigate();

  // Assets
  // Web
  const SeguroViaje =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/ViajeSalida/Compara-Web.webp';
  const SeguroVehiculo =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/AutoexpedibleAuto/Compara-Web.webp';
  const SeguroVida =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/EnfermedadesGraves/Compara-Web.webp';
  const SeguroOncologico =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/Oncologico/Cotiza-SeguroOncologico.webp';
  const SeguroVidaPlus =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/VidaPlus/Cotiza-VidaPlus.webp';

  // Móvil
  const SeguroViajeMovil =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/ViajeSalida/Compara-Movil.webp';
  const SeguroVehiculoMovil =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/AutoexpedibleAuto/Compara-Movil.webp';
  const SeguroVidaMovil =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/EnfermedadesGraves/Compara-Movil.webp';
  const SeguroOncologicoMovil =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/Oncologico/Cotiza-SeguroOncologico-Movil.webp';
  const SeguroVidaPlusMovil =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/VidaPlus/Cotiza-VidaPlus-Movil.webp';

  const handleNavigate = () => {
    const ruta = rutasCotizar.find(ruta => ruta.codigo === data.codigo)?.ruta;
    navigate(ruta, { state: data });
  };

  const renderData = {
    'SEG-DA': {
      renderImagen: SeguroVehiculo,
      renderImagenMovil: SeguroVehiculoMovil,
      renderDescricion:
        'Controla el monto que debes pagar en caso de un siniestro.',
    },
    'SEG-V': {
      renderImagen: SeguroViaje,
      renderImagenMovil: SeguroViajeMovil,
      renderDescricion:
        'Disfruta tus viajes, sin estrés y preocupación al estar protegido.',
    },
    'SEG-AEG': {
      renderImagen: SeguroVida,
      renderImagenMovil: SeguroVidaMovil,
      renderDescricion:
        'Protege contra las cargas financieras en momentos difíciles.',
    },
    'SEG-ONC': {
      renderImagen: SeguroOncologico,
      renderImagenMovil: SeguroOncologicoMovil,
      renderDescricion:
        'Asegura tu estabilidad financiera frente a un diagnóstico de cáncer.',
    },
    'SEG-VID': {
      renderImagen: SeguroVidaPlus,
      renderImagenMovil: SeguroVidaPlusMovil,
      renderDescricion:
        'Asegura tu estabilidad financiera frente a un diagnóstico de cáncer.',
    },
  };

  return (
    <div
      onClick={handleNavigate}
      className="cursor-pointer hover:scale-105 transition-all duration-300 "
      key={id}
    >
      <div className="card-cotizador w-full relative xs:w-[480px] xs:h-auto md:w-full md:h-full lmd:max-w-[800px] xlg:w-[260px] ">
        <div className="grid grid-cols-2 items-center xlg:hidden">
          <img
            src={renderData[data.codigo]?.renderImagenMovil}
            alt="Cotizacion Medica"
            className=""
          />
          <div className="absolute left-[45%] min-[350px]:left-[41%] md:left-0 mx-auto md:relative ">
            <h3 className="color-general fontPSemiBold text-xs xxxs:text-sm xs:text-base md:-translate-x-7 ">
              {seguro.replace('#', '')}
            </h3>
            <TextCustom
              text={renderData[data.codigo]?.renderDescricion}
              className="text-[#666666] mr-4 md:mr-0 text-[10px] xxxs:text-[10px] xs:text-sm md:-translate-x-7 "
              component="p"
              variant="p"
            />
            <ButtonCustom
              text="Cotizar"
              className="fontPSemiBold mt-3 w-[70px] h-[20px] text-[10px] xxxs:text-xs xs:text-sm xs:h-[25px] xs:w-[75px] md:-translate-x-7"
            />
          </div>
        </div>
        <div className="hidden xlg:block">
          <img
            src={renderData[data.codigo]?.renderImagen}
            alt="Cotizacion Medica"
            className="w-full h-full"
          />
          <div className="grid grid-rows-3 px-4 my-10 ">
            <h3 className=" color-general fontPSemiBold text-xl text-center ">
              {seguro.replace('#', '')}
            </h3>
            <TextCustom
              text={renderData[data.codigo]?.renderDescricion}
              className="text-[#666666] text-base text-center h-18"
              component="p"
              variant="p"
            />
            <div className="flex justify-center">
              <ButtonCustom
                text="Comprar"
                className="fontPSemiBold mt-7 text-base w-[120px] h-[40px]  "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCotizacion;
