//Funcion para cargar las llaves del formulario

export const dataInitialForm = tipoSeguro => {
  let keys = {};

  if (tipoSeguro === 'SEG-V') {
    keys = {
      destinoViaje: '',
      fechaViaje: '',
    };
  }

  if (
    tipoSeguro === 'SEG-FUNE' ||
    tipoSeguro === 'SEG-AEG' ||
    tipoSeguro === 'SEG-ONC'||
    tipoSeguro === 'SEG-VID'
  ) {
    keys = {
      genero: '',
    };
  }

  if(tipoSeguro === 'SEG-DA'){
    keys = {
        placa : '',
        tipoVehiculo:'',
        // marcaVehiculo:'',
        // anioVehiculo:'',
        //modeloVehiculo:'',
    }
  }

  if ( tipoSeguro === 'SEG-PM') {
    keys = {
      genero: '',
      tipoMoneda: '',
    };
  }

  return keys;
};
