// Hooks
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import { LoaderCotizar } from '../../atoms';
import { InformativaSeguro } from '../../templates';

// Const
import {
  glosarioDeducible,
  glosarioEnfermedades,
  glosarioViaje,
} from '../../../common/constants';

// Hooks
import { useApp } from '../../../hooks/others';

const Informativa = () => {
  const { seguros } = useApp();
  const location = useLocation();

  const segViaje =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/ViajeSalida/Icono-Big.svg';
  const segVehiculo =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/AutoexpedibleAuto/Icono-Big.png';
  const segEnfermedades =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/EnfermedadesGraves/Icono-Big.svg';
  const seguroOncologicoPlus =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/Oncologico/Icono-Small.svg';
  const seguroVidaPlus =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/VidaPlus/Icono-Small.webp';

  const imagenesSeguros = {
    'SEG-DA': segVehiculo,
    'SEG-V': segViaje,
    'SEG-AEG': segEnfermedades,
    'SEG-ONC': seguroOncologicoPlus,
    'SEG-VID': seguroVidaPlus,
  };

  const [seguroActual, setSeguroActual] = useState({});
  const [codigoSeguro, setCodigoSeguro] = useState('');
  const [glosario, setGlosario] = useState([]);
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    setCargando(true);
    switch (location.pathname) {
      case '/deducible-vehiculo':
        setCodigoSeguro('SEG-DA');
        setGlosario(glosarioDeducible);
        break;
      case '/seguro-viaje':
        setCodigoSeguro('SEG-V');
        setGlosario(glosarioViaje);
        break;
      case '/seguro-oncologico-plus':
        setCodigoSeguro('SEG-ONC');
        setGlosario([]);
        break;
      case '/seguro-vida-plus':
        setCodigoSeguro('SEG-VID');
        setGlosario([]);
        break;
      default:
        setCodigoSeguro('SEG-AEG');
        setGlosario(glosarioEnfermedades);
    }
    setTimeout(() => {
      setCargando(false);
    }, 1500);
  }, [location]);

  useEffect(() => {
    setSeguroActual(seguros.find(seguro => seguro.codigo === codigoSeguro));
  }, [codigoSeguro, seguros]);

  return (
    <>
      {cargando && <LoaderCotizar />}
      <InformativaSeguro
        descripcion={seguroActual?.descripcion}
        nombreSeguro={seguroActual?.nombre}
        titulo={seguroActual?.nombre}
        coberturas={seguroActual?.coberturas}
        codigoSeguro={codigoSeguro}
        imagen={imagenesSeguros[codigoSeguro]}
        preguntas={glosario}
      />
    </>
  );
};

export default Informativa;
