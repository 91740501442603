// Components
import { ButtonCustom, LoaderCotizar, TextCustom } from '../../atoms';
import HelpIcon from '@mui/icons-material/Help';

// Assets
import Logo from '../../../assets/images/Logos/INSLogo.svg';

// Hooks
import { useCotizador } from '../../../hooks/others';
import { useState } from 'react';

import { Link } from 'react-router-dom';
import { ModalCobertura, ModalControlValidacion } from '../../organisms';

import { apiEncontrarPersonaListaControl } from '../../../services/apis/Lista Control/listaControl';

//Functions
import { renderContactarControlValidacion } from '../../../common/constants/textoModales';


const CardResultadoMovil = ({
  idPlan = '',
  index = '',
  pasoSiguiente = () => null,
  coberturas = [],
  error = false,
  all = [],
}) => {
  const { dataCotizar } = useCotizador();
  const [modalCobertura, setModalCobertura] = useState(false);
  const [detalleCobertura, setDetalleCobertura] = useState('');
  const [modalValidacion, setModalValidacion] = useState(false);

  let data = '';

  const existeData = () => {
    const newData = all.find(dat => dat._id === idPlan)?.data;
    if (newData) {
      data = newData;
      return true;
    } else {
      return false;
    }
  };

  const handleCobertura = cobertura => {
    setModalCobertura(true);
    setDetalleCobertura(cobertura);
  };


  const onClick = async () => {
    const identificacion = dataCotizar.datosPersonales.numeroIdentificacion;
    let puedeComprar = true;

    const respuesta = async () => {
      const resp = await apiEncontrarPersonaListaControl({
        id_persona: identificacion,
      });
      return resp;
    };
    const resp = await respuesta();

    if (resp.data.no_cotizar) {
      puedeComprar = false;
    } else {
      puedeComprar = true;
    }

    if (puedeComprar) {
      pasoSiguiente(data, idPlan);
    } else {
      setModalValidacion(true);
    }
  };

  return (
    <>
      <div
        className={`bg-card rounded-md px-4 pt-0 h-full relative ${
          existeData() === false ? 'min-h[300px]' : 'h-full'
        }`}
        style={{
          width: '265px',
          display: error ? 'flex' : 'inline-block',
          background: error ? '#F8FBFD' : '#FFF',
          border: error ? '1px solid #00376334' : 'none',
          justifyContent: error && 'center',
          alignItems: error && 'center',
        }}
      >

  
        {!error ? (
          existeData() === false ? (
            <LoaderCotizar
              type="full"
              className={`texto-plan-slider-${index}`}
            />
          ) : (
            <>
              {all[index]?.data?.recomendado ? (
                <div className="bg-general rounded-t-md absolute w-full left-0 py-1 px-2">
                  <span className=" text-white fontPRegular text-sm">
                    Plan {data.orden ? data.orden : index + 1} (Recomendado)
                  </span>
                </div>
              ) : (
                <div className="bg-[#E5EBEF] rounded-t-md absolute w-full left-0 py-1 px-2">
                  <span className=" text-[#003763] fontPSemiBold text-sm">
                    Plan {data.orden ? data.orden : index + 1}
                  </span>
                </div>
              )}

              <div className="mt-14 pt-1 text-center border-b pb-3">
                <img
                  src={Logo}
                  alt="Imagen de aseguradora"
                  width={150}
                  className="mx-auto"
                />
                <TextCustom
                  text={data.codigoComercial}
                  component="p"
                  className="fontPRegular color-general text-xs -mt-[5px]"
                />
                {data?.precios?.map((precio, index) => {
                  if (precio?.codigo === 'A') {
                    return (
                      <div key={index}>
                        <TextCustom
                          text={precio?.valor}
                          component="p"
                          className="pt-5 px-2 fontPSemiBold color-general text-2xl"
                        />
                        <TextCustom
                          text="Prima total"
                          component="p"
                          className="pt-0 fontPRegular text-base"
                        />
                        <TextCustom
                          text="(IVA Incluido)"
                          component="p"
                          className="pt-0 fontPRegular text-xs color-gray-secondary"
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              {dataCotizar.seguro.codigo !== 'SEG-V' &&
                dataCotizar.seguro.codigo !== 'SEG-DA' &&
                dataCotizar.seguro.codigo !== 'SEG-VID' &&
                dataCotizar.seguro.codigo !== 'SEG-AEG' && (
                  <div className="border-b pb-5 text-center mt-4 h-12">
                    <TextCustom
                      text="Prima Mensual:"
                      className=" color-ligth-gray fontPRegular text-sm"
                      component="p"
                      variant="p"
                    />
                    {data?.precios?.map(
                      (precio, index) =>
                        precio?.codigo === 'M' && (
                          <div key={index}>
                            <TextCustom
                              text={precio?.valor ? precio?.valor : 'No aplica'}
                              className=" fontPSemiBold text-sm"
                              component="p"
                              variant="p"
                            />
                          </div>
                        ),
                    )}
                  </div>
                )}
              {coberturas.map((cobertura, index) => (
                <div
                  className={`p-2 flex justify-between items-center ${
                    index + 1 !== coberturas.length && 'border-b'
                  }`}
                  key={index}
                >
                  <TextCustom
                    text={`${cobertura.nombre}`}
                    component="div"
                    className={`fontPRegular text-[0.8rem]`}
                  />
                  {cobertura?.detalle && (
                    <div
                      className="cursor-pointer z-10"
                      onClick={() => handleCobertura(cobertura)}
                    >
                      <HelpIcon
                        fontSize="small"
                        sx={{
                          fill: '#0099B7',
                        }}
                      />
                    </div>
                  )}
                  <TextCustom
                    text={
                      cobertura.codigo === '54J'
                        ? 'Hasta ' + data.coberturas[index]?.descripcion
                        : cobertura.codigo === '77P' || cobertura.codigo === '78O'
                          ? 'Amparado'
                          : data.coberturas[index]?.descripcion
                    }
                    component="div"
                    className={`fontPSemiBold text-[0.8rem]`}
                  />
                </div>
              ))}
              <div className="text-center py-10">
                <ButtonCustom
                  text={`${data?.pasos ? 'Comprar' : 'Solicitar'}`}
                  width="160px"
                  className="fontPSemiBold"
                  onClick={onClick}
                />
              </div>
              <Link
                to={data?.linkCondiciones}
                className="fontPRegular color-general text-sm flex justify-center underline cursor-pointer"
                target="_blank"
              >
                Condiciones Generales
              </Link>
            </>
          )
        ) : (
          <div className="color-general text-xl fontPSemiBold text-center">
            Resultado <br /> no disponible
          </div>
        )}
      </div>
      <ModalCobertura
        open={modalCobertura}
        setOpen={setModalCobertura}
        titulo={detalleCobertura?.nombre}
        cobertura={detalleCobertura?.detalle}
      />

      <ModalControlValidacion
        open={modalValidacion}
        setOpen={setModalValidacion}
        titulo="¡Verificación necesaria!"
        contenido="En este momento no podemos proceder debido a una revisión de nuestros registros"
        contactar={renderContactarControlValidacion()}
      />
    </>
  );
};

export default CardResultadoMovil;
