// Components
import { TextCustom } from '../../atoms';
import { contenidoCards } from '../../../common/constants';

//styles
import './style.css';

const CardsAhorros = () => {
  return (
    <>
      {contenidoCards.map(card => (
        <ul key={card.titulo} className="">
          <li className="list-disc ml-0 text-white">
            <TextCustom
              text={card.titulo}
              className="text-white font-bold fontPSemiBold text-[13px] md:text-[16px] lg:text-[20px]"
              component="p"
              variant="p"
            />
            <TextCustom
              text={card.subtitulo}
              className="text-white  fontPRegular mt-1 text-[13px] md:text-[16px]  "
              component="p"
              variant="p"
            />
          </li>
        </ul>
      ))}
    </>
  );
};

export default CardsAhorros;
