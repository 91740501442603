const NavigationCarrouselCotizaciones = ({ numPlanes, children }) => {
    return (
      <div
      className={`sticky top-28 ${numPlanes >= 2 ? "" : "lg:hidden"} ${numPlanes >= 3 ? " " : "xl:hidden"} ${numPlanes > 3 ? "" : "2xl:hidden"}  `}
      >
        {children}
      </div>
    );
  };
  
  export default NavigationCarrouselCotizaciones;
  