import React from 'react';

// Components
import { AppRouter } from './components/routes';

const App = () => {
  return <AppRouter />;
};

export default App;
