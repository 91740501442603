// Components
import { Divider } from '@mui/material';
import TextCustom from '../TextCustom';

const AlertCardCustom = ({ show = false, className = '' }) => {
  // Assets
  const Listo =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Contacto/Listo.webp';

  return show ? (
    <div
      className={`bg-[#E5F4F7] w-2/3 mx-auto rounded-[20px] py-1 max-h-[200px] ${className}`}
    >
      <div className="flex justify-center">
        <div className="flex flex-col lg:flex-row gap-10 items-center">
          <img
            src={Listo}
            alt="Imagen listo"
            className="max-h-[148px] max-w-[148px]"
          />
          <div className="text-center lg:text-left">
            <TextCustom
              text="¡Bien hecho!"
              className="color-general fontPSemiBold text-[30px]"
              component="p"
              variant="p"
            />
            <TextCustom
              text="Tu mensaje ha sido enviado con éxito. En breve recibirás una respuesta."
              className="mb-2 fontPRegular"
              component="p"
              variant="p"
            />
            <Divider />
            <TextCustom
              text="Gracias por comunicarte con nosotros."
              className="mt-2 fontPRegular"
              component="p"
              variant="p"
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AlertCardCustom;
