import { Button, Dialog, DialogContent } from '@mui/material';

import { ReactComponent as CloseIcon } from '../../../assets/icons/Modales/Icono-Cerrar.svg';

import { IconButtonCustom, TextCustom } from '../../atoms';
import { DialogCustom } from '../../templates';

export default function ModalSeguroViaje({
  icon,
  showModal,
  setShowModal,
  handleNavigatePasoPersonal,
}) {
  return (
    <Dialog
      PaperProps={{ className: 'rounded-[20px] max-w-[700px]' }}
      open={showModal}
      setOpen={setShowModal}
      disabledDismiss
    >
      <IconButtonCustom
        className="absolute right-0"
        icon={<CloseIcon />}
        onClick={() => setShowModal(false)}
        size={44}
      />

      <DialogContent className="flex flex-col md:flex-row justify-center items-center py-[77px] lg:px-[75px] gap-7">
        <div className="h-auto w-full max-w-[162px] aspect-square border-[#E5F4F7] border-[10px] rounded-full">
          <img
            src={icon}
            alt="IconoResultado"
            className="w-full h-auto aspect-square"
            // className={`iconoResultado-${dataCotizar.seguro.codigo}`}
          />
        </div>
        <div className="text-center md:text-start">
          <TextCustom
            text="¡Felicidades!🥳" 
            className="fontPSemiBold color-general text-xl md:text-2xl lg:text-3xl"
          />

          <div className="mt-[5px] mb-5 space-y-3 text-sm fontPRegular">
            <p>Tu seguro ha sido suscrito y pagado.</p>
            <p className="">
              <span className="fontPSemiBold">¿Quieres contratar otros{' '}
              Seguros Viajeros para
              proteger a quienes te acompañaran?</span>
            </p>
          </div>

          {/* Actions */}
          <div className="flex flex-col lg:flex-row items-center md:flex-row gap-[10px]">
            <Button
              className="bg-[#003763] text-white w-[180px] h-[40px] grid place-items-center rounded-[100px] fontPSemiBold normal-case"
              onClick={() => {
                handleNavigatePasoPersonal()
                setShowModal(false)
              }}
            >
              Si, contratar otro
            </Button>

            <Button
              variant="outlined"
              className="text-[#003763] border-[#003763] w-[180px] h-[40px] grid place-items-center rounded-[100px] fontPSemiBold normal-case"
              onClick={() => setShowModal(false)}
            >
              No, ver el detalle
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
