import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';

// Components
import { TextCustom } from '../../atoms';

// Const
import { rutasCotizar } from '../../../common/constants';

// Hooks
import { useApp } from '../../../hooks/others';

// Utils
import { renderImagen, renderNombre } from '../../../core/utils';
import {CarouselCustom} from "../../organisms";
import {SwiperSlide} from "swiper/react";
import { useTheme } from '@mui/material/styles';
import {useMediaQuery} from "@mui/material";

const CardSeguroTitulo = () => {
  const { seguros } = useApp();
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
const segurosCant = Array.isArray(seguros) ? (matches ? (seguros.length > 3 ? seguros.length === 4 ? 3 : Math.round(seguros.length / 2) : Number(seguros.length)) : 3) : 1
  const VidaIcon =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Proximamente/Icono-SeguroVida.webp';
  const Oncologico =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Proximamente/Icono-Oncologico.webp';
  const Proteccion =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Proximamente/Icono-ProteccionMultiple.webp';
  const asegurameMedico =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/EnfermedadesGraves/Icono-Small.webp';

  const handleClick = (codigoSeguro, tipo) => {
    const ruta = rutasCotizar.find(ruta => ruta.codigo === codigoSeguro);

    navigate(ruta.ruta, { state: tipo });
  };

  useEffect(() => {
    if (seguros.length !== 0) {
      setInitialized(true);
    }
  }, [seguros]);

  const optionsSeguroMovil = {
    items: 4,
    loop: true,
    margin: 0,
    nav: false,
    dots: false,
    autoWidth: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        autoplay: true,
        autoplayTimeout: 2000,
        loop: true,
      },
      760: {
        loop: false,
        autoplay: false,
        mouseDrag: false,
      },
    },
  };

  return (
    <>
      <div className="hidden color-ejemplo contenedor-seguros-card justify-center my-5 gap-4 xl:my-0 xl:grid xl:grid-cols-2 max-w-[100vw]">
        {seguros.map(seguro => (
          <div className="flex flex-col items-center" key={seguro.codigo}>
            <div
              className="bg-card card-seguros-home flex justify-center items-center px-0 py-0 cursor-pointer hover:scale-105 transition-all duration-300 w-[75px] h-[75px] lg:w-[85px] lg:h-[85px] max-[]: xl:grid xl:grid-cols-2 xl:w-[300px] xl:h-[125px] xl:px-9 xl:py-5"
              onClick={() => handleClick(seguro.codigo, seguro)}
            >
              <div>
                <TextCustom
                  text={
                    seguro.nombre.includes('Seguro de ')
                      ? 'Seguro de'
                      : 'Seguro'
                  }
                  className="hidden text-xs fontPRegular color-general xl:block"
                  component="p"
                  variant="p"
                />
                <TextCustom
                  text={renderNombre(seguro.nombre)}
                  className="hidden fontPSemiBold color-general text-xl text-nombre-seguro xl:block"
                  component="p"
                  variant="p"
                />
              </div>
              <div className="flex justify-center items-center xl:justify-end xl:items-center">
                {renderImagen[seguro.codigo]}
              </div>
            </div>
          </div>
        ))}
        
        {/* <div className="flex flex-col items-center">
          <div className="bg-card card-seguros-home flex justify-center items-center px-0 py-0 transition-all duration-300 w-[75px] h-[75px] lg:w-[85px] lg:h-[85px] xl:grid xl:grid-cols-2 xl:w-[300px] xl:h-[125px] xl:px-9 xl:py-5">
            <div>
              <TextCustom
                text="Seguro de"
                className="hidden text-xs fontPRegular color-general xl:block"
                component="p"
                variant="p"
              />
              <TextCustom
                text="Vida"
                className="hidden fontPSemiBold color-general text-xl text-nombre-seguro xl:block"
                component="p"
                variant="p"
              />
              <TextCustom
                text="Próximamente"
                className="hidden text-xs fontPRegular font-light color-gray-secondary xl:block"
                component="p"
                variant="p"
              />
            </div>
            <div className="flex justify-center items-center xl:justify-end xl:items-center">
              <img
                id="vida"
                src={VidaIcon}
                alt="asegurameVida"
                className="icono-cotizar"
              />
            </div>
          </div>
        </div> */}
        <div className="flex flex-col items-center">
          <div className="bg-card card-seguros-home flex justify-center items-center px-0 py-0 transition-all duration-300 w-[75px] h-[75px] lg:w-[85px] lg:h-[85px] xl:grid xl:grid-cols-2 xl:w-[300px] xl:h-[125px] xl:px-9 xl:py-5">
            <div>
              <TextCustom
                text="Seguro de"
                className="hidden text-xs fontPRegular color-general xl:block"
                component="p"
                variant="p"
              />
              <TextCustom
                text="Protección Múltiple"
                className="hidden fontPSemiBold color-general text-xl text-nombre-seguro xl:block"
                component="p"
                variant="p"
              />
              <TextCustom
                text="Próximamente"
                className="hidden text-xs fontPRegular font-light color-gray-secondary xl:block"
                component="p"
                variant="p"
              />
            </div>
            <div className="flex justify-center items-center xl:justify-end xl:items-center">
              <img
                id="proteccion"
                src={Proteccion}
                alt="asegurameProteccion"
                className="icono-cotizar"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`max-w-[100vw] mx-auto text-center xl:hidden mb-1 lg:mb-12 mt-2`}
      >
        { initialized && <CarouselCustom spaceBetween={0} slidesPerView={segurosCant}>
          {seguros.map((seguro, idx) => (
            <SwiperSlide key={idx}>
              <div className="flex flex-col items-center" key={seguro.codigo}>
                <div
                  className="bg-card card-seguros-home flex justify-center items-center px-0 py-0 cursor-pointer hover:scale-105 transition-all duration-300 w-[75px] h-[75px] lg:w-[85px] lg:h-[85px] xl:grid xl:grid-cols-2 xl:w-[300px] xl:h-[125px] xl:px-9 xl:py-5"
                  onClick={() => handleClick(seguro.codigo, seguro)}
                >
                  <div className="flex justify-center items-center xl:justify-end xl:items-center">
                    {renderImagen[seguro.codigo]}
                  </div>
                </div>
                <TextCustom
                  text="Seguro de"
                  className="text-[10px] mt-2 fontPRegular color-general"
                  component="p"
                  variant="p"
                />
                <TextCustom
                  text={renderNombre(seguro.nombre)}
                  className="fontPSemiBold color-general text-xs text-center w-[95px] lg:w-[110px] lg:text-base"
                  component="p"
                  variant="p"
                />
              </div>
            </SwiperSlide>
          ))}
        </CarouselCustom>
        }
      </div>
    </>
  );
};

export default CardSeguroTitulo;
