import React, { memo } from 'react';

// Components
import { InputAdornment, TextField } from '@mui/material';
import { TextCustom, IconButtonCustom } from '../';

// Core
import {
  validTextInput,
  validInputInitialNumbers,
} from '../../../core/validations';

//assets
import { ReactComponent as IconoError } from '../../../assets/icons/Icono-Error.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Styles
import { colors } from '../../styles/theme';

const { general, black, white, ligthGray, error, green, bordes } = colors;

const TextInputTelefono = ({
  name = '',
  value = '',
  setValue = () => null,
  onBlur = () => null,
  onEnter = () => null,
  placeholder = '',
  type = 'text',
  typesValidation = '',
  validInitNumbers = [],
  maxLength = null,
  className = '',
  iconMode = 'adornment',
  iconTypeColor = 'primary',
  iconOnClick = () => null,
  msgError = '',
  disabled = false,
  multiline = false,
  required = false,
  success = false,
  errorInput = false,
  fontSize = 18,
  borderStyle = false,
  onChange = () => null,
  onKeyUp = () => null,
}) => {
  const handleOnChange = e => {
    const inputValue = e.target.value;
    let isValid = true;
    if (validInitNumbers.length) {
      isValid = validInputInitialNumbers(inputValue, validInitNumbers);
    } else {
      isValid = validTextInput(inputValue, typesValidation);
    }
    if (isValid || inputValue === '' || !inputValue) {
      setValue(inputValue);
    }

    onChange(e.target.value);
  };

  const renderIcon = icon => {
    if (icon) {
      return iconMode === 'button' ? (
        <IconButtonCustom
          icon={icon}
          onClick={iconOnClick}
          typeColor={iconTypeColor}
        />
      ) : (
        <InputAdornment position="start">{icon}</InputAdornment>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={`flex flex-col relative ${className} `}>
      <TextField
        label={name}
        value={value}
        onChange={handleOnChange}
        onBlur={onBlur}
        onKeyDown={event => {
          if (event.code === 'Enter') {
            onEnter();
          }
        }}
        onKeyUp={onKeyUp}
        variant="outlined"
        size="large"
        multiline={multiline}
        minRows={multiline ? '3' : '1'}
        maxRows={multiline ? '4' : '1'}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        required={required}
        inputProps={{
          maxLength: maxLength,
          style: {
            textAlign: 'left',
            padding: '16px 14px',
            fontFamily: 'Poppins-Regular',
            borderRadius: 20,
            color:
              msgError.length > 0 || errorInput
                ? error
                : success
                ? green
                : general,
            fontWeight: 'bold',
          },
        }}
        InputProps={{
          startAdornment: (
            <span className="border-r border-[#e3e3e3] py-[16px] pl-[1px] pr-[14px] fontPSemiBold text-[#003763]">
              +506
            </span>
          ),
          endAdornment:
            msgError.length > 0 || errorInput
              ? renderIcon(<IconoError />)
              : success && <CheckCircleOutlineIcon color="success" />,
        }}
        sx={{
          /* aqui se quita el borde al hacer hover */
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor:
                msgError.length > 0 || errorInput
                  ? error
                  : success
                  ? green
                  : borderStyle
                  ? bordes
                  : 'transparent',
            },
          },

          '& legend': {
            marginLeft: 2,
            fontSize: fontSize * 0.82,
          },
          '& .MuiInputBase-root': {
            '& fieldset': {
              borderRadius: 5,
              border:
                msgError.length > 0 || success || errorInput
                  ? 2
                  : borderStyle
                  ? 1
                  : 0,
              borderColor:
                msgError.length > 0 || errorInput
                  ? error
                  : success
                  ? green
                  : borderStyle
                  ? bordes
                  : 'transparent',
              color: general,
              boxShadow: !borderStyle && 'var(--sombra-secondary)',
            },

            /* aqui se quita/agrega borde mientras haga foco  */

            '&.Mui-focused fieldset': {
              borderColor:
                msgError.length > 0 || errorInput
                  ? error
                  : success
                  ? green
                  : borderStyle
                  ? general
                  : 'transparent',
              color: black,
              fontSize: fontSize,
            },
          },
          '& .MuiInputLabel-asterisk': {
            color: error,
          },
          '& .MuiInputLabel-shrink': {
            marginLeft: 2,
            color: black,
            fontSize: fontSize,
            fontWeight: '600',
            '& .MuiInputLabel-asterisk': {
              color: error,
              display: 'inline',
            },
          },
          backgroundColor: disabled ? ligthGray : white,
          borderRadius: 5,
          marginTop: 1,
        }}
      />

      {msgError && (
        <div className="flex gap-2 items-center mt-2">
          <TextCustom
            text={msgError}
            className="text-xs fontPRegular color-error"
          />
        </div>
      )}
    </div>
  );
};

export default memo(TextInputTelefono);
