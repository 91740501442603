//components
import HelpIcon from '@mui/icons-material/Help';

//styles
import './style.css';

const Preguntita = ({ titulo = '' }) => {
  return (
    <div className="custom-tooltip hover:cursor-pointer">
      <HelpIcon
        fontSize="small"
        sx={{
          fill: '#0099B7',
        }}
      />
      <span className="custom-tooltiptext">{titulo}</span>
    </div>
  );
};

export default Preguntita;
