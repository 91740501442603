export const glosarioDeducible = [
  {
    titulo: 'Deducible de Auto',
    datos: [
      {
        pregunta: 'Deducible',
        respuesta: (
          <span>
            Monto o Porcentaje que representa la participación económica del
            Asegurado en una indemnización producto de un reclamos.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Suma Asegurada',
        respuesta: (
          <span>
            Monto elegido por el Asegurado y que corresponde a la suma que la
            Aseguradora deberá pagarle en virtud de la activación de la póliza.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Evento',
        respuesta: (
          <span>
            Es el deducible rebajado de la indemnización realizada sobre la
            póliza principal que ampara el vehículo, con cargo a la cobertura de
            responsabilidad civil o de daño directo.
          </span>
        ),
        estado: false,
      },
    ],
  },
];

export const glosarioEnfermedades = [
  {
    titulo: 'Enfermedades Graves',
    datos: [
      {
        pregunta: 'Accidente Cerebro Vascular (ACV)',
        respuesta: (
          <span>
            Es cualquier accidente vascular cerebral de duración superior a 24
            horas, que produzca secuelas neurológicas persistentes de más de 15
            días, se incluyen aquellos productos de infarto del tejido cerebral,
            hemorragia intracraneal.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Cáncer',
        respuesta: (
          <span>
            Se refiere a la presencia de un tumor que se caracteriza por ser
            progresivo, de crecimiento incontrolado, con diseminación de células
            malignas e invasión y destrucción de tejido normal circundante y a
            distancia.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Cirugía Estética',
        respuesta: (
          <span>
            Cirugía plástica que se realiza para mejorar la apariencia cosmética
            general al remodelar y ajustar la anatomía normal para que se vea
            visualmente más atractiva y la cual no se considera estrictamente
            necesaria.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Cirugía Reconstructiva',
        respuesta: (
          <span>
            Cirugía plástica que se realiza para restablecer la función y la
            apariencia normal y corregir las deformidades creadas por el cáncer.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Disputabilidad',
        respuesta: (
          <span>
            Cláusula que permite a la Aseguradora durante un período de tiempo
            disputar la validez del contrato por reticencia o declaraciones
            inexactas del asegurado, así como por enfermedades preexistentes no
            manifestadas al perfeccionarse el contrato.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Enfermedad Grave',
        respuesta: (
          <span>
            Cuando un médico especialista habilitado para el ejercicio legal de
            la profesión acredite que el Asegurado ha sido diagnosticado con:
            <ul>
              <li>Cáncer</li>
              <li>Accidente Cerebro Vascular</li>
              <li>Insuficiencia renal</li>
              <li>Infarto miocardio</li>
            </ul>
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Infarto Agudo del Miocardio',
        respuesta: (
          <span>
            Es la muerte o necrosis de una parte del músculo cardíaco
            (miocardio) como resultado de una interrupción brusca del aporte
            sanguíneo a la zona respectiva y cuyo diagnóstico debe ser
            confirmado simultáneamente por los dos siguientes criterios:
            <ul>
              <li>Cambios agudos en el electrocardiograma.</li>
              <li>Elevación de las enzimas cardíacas</li>
            </ul>
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Insuficiencia Renal',
        respuesta: (
          <span>
            Insuficiencia crónica irreversible de ambos riñones que exija la
            necesidad de someterse regularmente a diálisis peritoneal o
            hemodiálisis.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Período de Carencia',
        respuesta: (
          <span>
            Periodo de tiempo con posterioridad a la fecha de emisión de la
            póliza, durante el cual la Aseguradora no amparará ninguna
            reclamación.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Pre-Existencia',
        respuesta: (
          <span>
            Toda aquella enfermedad o lesión congénita o no, accidente,
            discapacidad física, así como sus secuelas que, con anterioridad a
            la adquisición de la póliza y que haya sido:
            <ul>
              <li>Diagnosticada por un médico.</li>
              <li>
                De la que por sus síntomas o signos no pudieron pasar
                inadvertidas para el Asegurado o terceras personas.
              </li>
              <li>Recibido tratamiento médico, servicios o suministros.</li>
              <li>
                Practicado o le recomendaron exámenes para diagnósticos
                pertinentes.
              </li>
              <li>Tomado drogas o medicinas recetadas o recomendadas.</li>
            </ul>
          </span>
        ),
        estado: false,
      },
    ],
  },
];

export const glosarioViaje = [
  {
    titulo: 'Seguro de viaje',
    datos: [
      {
        pregunta: 'Viaje',
        respuesta: (
          <span>
            Significa un destino específico fuera del país, el cual debe
            comenzar y terminar dentro de un período 180 como máximo y quedar
            comprendido dentro del período de vigencia de la póliza adquirida.
            Un viaje comienza al recibirse la aprobación oficial de salida de
            parte de la Oficina de Migración de Costa Rica y termina el día que
            se registra el ingreso por parte de la Oficina de Migración de la
            República de Costa Rica.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Accidente',
        respuesta: (
          <span>
            Suceso provocado por una acción repentina, fortuita e imprevista en
            forma involuntaria y que da lugar a una lesión corporal traumática.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Deducible',
        respuesta: (
          <span>
            Monto o Porcentaje indicado que representa la participación
            económica del Asegurado en la indemnización de los reclamos, bajo
            las coberturas en las que aplique.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Deportes extremos',
        respuesta: (
          <span>
            Rafting, uso de vehículos acuáticos con / sin motor, canopy, bungee
            jumping, sky walk o sky trek, snorkeling, pesca deportiva, rapel,
            escalar montañas, parapente, paracaidismo, alpinismo, boxeo, alas
            delta, vuelo libre, carrera de caballos, cacería mayor,
            automovilismo, motociclismo, motocross, bicicross, cuadriciclos,
            cuadracross, surfing, futbol americano, rugby, artes marciales
            mixtas, toreo y participación en corridas de toros, snowboard, esquí
            (cualquier modalidad), skateboard, kite surf, moto náutica, wind
            surf, moto de nieve, sand board y grass boards.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Emergencia Médica',
        respuesta: (
          <span>
            Aparición inesperada de una condición médica con síntomas severos
            que amenazan la vida y que requiere atención médica inmediata 24
            horas después de su aparición.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Enfermedad Aguda',
        respuesta: (
          <span>
            Enfermedad de aparición súbita y repentina, de evolución rápida, con
            una evolución que no sobrepase los 14 días y no corresponda o sea
            consecuencia de una enfermedad preexistente.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Enfermedad Epidémica',
        respuesta: (
          <span>
            Enfermedad aguda, producida por agentes biológicos o no biológicos,
            que según los epidemiólogos tienen un grado grave o fatal de
            patogenicidad, virulencia o letalidad, que pueda propagarse con
            rapidez o que presente un crecimiento acelerado y considerado
            anormal en el número de casos que se presentan en un país o región o
            que haya sido declarada como tal por los organismos de salud
            oficiales internacionales o de un país o región facultados para este
            efecto, debido a su impacto o implicaciones en la salud pública.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Enfermedad Pandémica',
        respuesta: (
          <span>
            Enfermedad aguda, producida por agentes biológicos o no biológicos,
            que según los epidemiólogos tienen un grado grave o fatal de
            patogenicidad, virulencia o letalidad, que pueda propagarse con
            rapidez o que presente un crecimiento acelerado y considerado
            anormal en el número de casos que se presentan simultáneamente en
            más de un país o continente o que haya sido declarada como tal por
            la Organización Mundial de la Salud facultada para este efecto,
            debido a su impacto o implicaciones en la salud pública.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Enfermedad Preexistente',
        respuesta: (
          <span>
            Toda aquella enfermedad o lesión congénita o no, accidente,
            discapacidad física, así como sus secuelas que, con anterioridad a
            la adquisición de la póliza y que haya sido:
            <ul>
              <li>Diagnosticada por un médico.</li>
              <li>
                De la que por sus síntomas o signos no pudieron pasar
                inadvertidas para el Asegurado o terceras personas.
              </li>
              <li>Recibido tratamiento médico, servicios o suministros.</li>
              <li>
                Practicado o le recomendaron exámenes para diagnósticos
                pertinentes.
              </li>
              <li>Tomado drogas o medicinas recetadas o recomendadas.</li>
            </ul>
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Hospitalización',
        respuesta: (
          <span>
            Ocurre cuando el Asegurado es ingresado en un hospital, registrado
            como paciente de cama, por un período continuo no menor de
            veinticuatro (24) horas
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Práctica Profesional de Deporte',
        respuesta: (
          <span>
            MSe entiende aquella en la que el deportista se dedica
            voluntariamente a la práctica del deporte dentro o bajo la dirección
            de la organización de un club, federación o entidad deportiva, a
            cambio de una retribución la cual constituye su principal medio de
            ingreso.
          </span>
        ),
        estado: false,
      },
      {
        pregunta: 'Pérdida Temporal de Equipaje',
        respuesta: (
          <span>
            Extravío temporal del equipaje registrado con la línea aérea o la
            naviera por más de 12 horas desde el momento de llegada a su destino
            que no sea su residencia.
          </span>
        ),
        estado: false,
      },
    ],
  },
];
