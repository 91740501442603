import React, { useEffect } from 'react';

// Components
import { PasosItem } from '../../molecules';

//hooks
import { useCotizador } from '../../../hooks/others';

//sytles
import './style.css';
import { colors } from '../../styles/theme';

// Utils
import { changeQueryParam } from '../../../core/utils';

const { primary, bordes } = colors;

const TabsSeguros = ({
  lineSize = 60,
  inputProps = [],
  paso = 2,
  setPaso = () => null,
}) => {
  const { setNumPasos, pasos, setPasos } = useCotizador();

  useEffect(() => {
    setNumPasos(inputProps.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputProps]);

  const handlePaso = index => {
    changeQueryParam('paso', paso);

    setPaso(index);
  };

  useEffect(() => {
    if (paso === 1 || paso === 2 || paso === 3) {
      if (pasos.length > 3) {
        setPasos(pasos.slice(0, 3));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paso, pasos]);

  return (
    <div className="w-100">
      <div
        className={`w-100 flex items-center py-12 pb-8 justify-center contenedor-principal-tabs ${
          paso === 1 ? "centrar-tabs-pasos" : "block"
        }`}
      >
        {inputProps.map((input, index) => (
          <>
            <PasosItem
              name={input?.alias ?? input.nombre}
              isActual={paso === index + 1}
              isChecked={paso > index + 1 ? true : false}
              onClick={handlePaso}
              enabled={
                index + 1 < paso ||
                (paso === inputProps.length &&
                  index !== inputProps.length - 1) ||
                (paso === inputProps.length && index === inputProps.length - 1)
              }
              paso={index + 1}
              pasoActual={paso}
              key={index}
            />
            <div
              className={`mx-4 line-tab ${
                inputProps.length === index + 1 && 'hidden'
              } ${inputProps.length > 3 ? 'line-tab-6' : ''} `}
              style={{ width: lineSize }}
              key={index + 2}
            >
              <hr
                style={{
                  borderColor: paso > index + 1 ? primary : bordes,
                }}
              />
            </div>
          </>
        ))}
      </div>
      <div>
        {inputProps.length !== 0 &&
          inputProps.map((input, index) => (
            <div
              key={index}
              className={`w-100 contenedor-pasos ${
                paso === index + 1
                  ? paso === 1
                    ? 'flex'
                    : 'flex height-pasos '
                  : 'hidden'
              }`}
            >
              {input.formulario}
            </div>
          ))}
      </div>
    </div>
  );
};

export default TabsSeguros;
