import { Swiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import './style.css'

import { Autoplay, Navigation } from "swiper/modules";


const CarouselCustom = ({ slidesPerView, spaceBetween = 0, children, autoplay = false, navigation = false, className }) => {
  return (
    <div className={`w-full h-full ${className}`}>
      <Swiper
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        loop={true}
        autoplay={autoplay}
        navigation={navigation}
        modules={[Autoplay, Navigation]}
      >
        {children}
      </Swiper>
    </div>
  )
}


export default CarouselCustom;