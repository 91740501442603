import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { ButtonCustom } from '../../atoms';

// Assets
import { useCotizador } from '../../../hooks/others';

const PasoRechazado = () => {
  const { dataCotizar } = useCotizador();
  const navigate = useNavigate();
  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);

  // Assets
  const Telefono =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Seguros/Proceso-Suscripcion.svg';

  const handleResize = () => {
    setAnchoPantalla(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleGoHome = () => {
    navigate('/');
  };
  return (
    <div
      className="w-screen fixed top-[84px] md:top-[60px] z-10 flex justify-center items-center bg-white flex-col text-center"
      style={{
        height:
          anchoPantalla > 600 ? 'calc(100vh - 60px)' : 'calc(100vh - 84px)',
      }}
    >
      <img src={Telefono} className="p-4" alt="Telefono" />
      <div className="fontPSemiBold color-general text-2xl my-6 md:text-4xl md:my-9">
        ¡Completa tu suscripción <br /> junto a nosotros!
      </div>
      <div>
        <p className="fontPRegular text-sm md:text-base px-3 md:px-0">
          ¡{dataCotizar.datosPersonales.primerNombre}{' '}
          {dataCotizar.datosPersonales.primerApellido}, pronto nos pondremos en
          contacto contigo <br />
          para continuar y finalizar tu proceso de suscripción! <br /> <br />
          Si tienes alguna pregunta o necesitas más información, <br />
          no dudes en comunicarte con nosotros a <b>(506) 8647-3773</b>
        </p>
      </div>
      <ButtonCustom
        text="Ir a inicio"
        className="my-8"
        width="180px"
        onClick={handleGoHome}
      />
    </div>
  );
};

export default PasoRechazado;
