import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import NavigationCarrouselCotizaciones from './NavigationCarrouselCotizaciones';

//components
import {
  ButtonCustom,
  TextCustom,
  AlertDiasGratis,
  AlertCustom,
} from '../../atoms';
import {
  CardResultado,
  CardSeleccionada,
  ResultadosMovil,
} from '../../molecules';
import ModalEnfermedades from '../ModalEnfermedades';
import TituloFiltros from './TituloFiltros';
import ModalCobertura from '../ModalCobertura';

//styles
import './style.css';

// Hooks
import { useCotizador, useForm } from '../../../hooks/others';

// API's
import {
  apiActualizarOportunidad,
  apiEnviarResumenCotizacion,
  apiPostCotizar,
  apiPostPlan,
  apiVerificarDataCRM,
  apiCrearRiesgoAsegurado,
  apiCrearBitacora,
} from '../../../services/apis';

// UTILS
import {
  changeQueryParam,
  fontBoldDetalleCotizacion,
  generarParamsOportunidad,
  renderForm,
} from '../../../core/utils';

import Next from '../../../assets/icons/Icono-Siguiente.svg';
import Prev from '../../../assets/icons/Icono-Regresar.svg';
import Ayuda from '../../../assets/images/Cotizador/Resultado-Ayuda.png';
import HelpIcon from '@mui/icons-material/Help';

// Import Swiper styles
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ModalOncologico from '../ModalOncologico';
import { renderBoldTextOncologico } from '../../../common/constants/textoModales';

import { CarouselCustom } from '../index';
import { SwiperSlide } from 'swiper/react';

import { apiGetEtapa } from '../../../services/apis/Etapas/apiEtapas';

const PasoResultado = () => {
  //context
  const {
    dataCotizar,
    paso,
    setPaso,
    pasos,
    setPasos,
    planes,
    plan,
    setPlan,
    setNumPasos,
    detallesCotizacion,
    idCotizacion,
    idOportunidad,
    setRechazado,
    setBeneficiarios,
    setSumasAseguradas,
    isRecuperandoCotizacion,
    setCargando,
    promocion,
    setPromocion,
    pasoActual,
    setPasoActual,
    setPasoAnterior,
    bienes,
  } = useCotizador();

  const navigate = useNavigate();

  //states
  const [showPromocion, setShowPromocion] = useState(false);
  const [dataCardSlider, setDataCardSlider] = useState([]);
  const [dataCardSeleccionada, setDataCardSeleccionada] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [dataCardSlideMovil, setDataCardSlideMovil] = useState([]);
  const [sort, setSort] = useState('');
  // const [sortNew, setSortNew] = useState('');
  const [numPlanes, setNumPlanes] = useState(0);
  const [modal, setModal] = useState(false);
  const [alertCoti, setAlertCoti] = useState(false)
  const [dataPlanSeleccionado, setDataPlanSeleccionado] = useState('');
  const [idPlanSeleccionado, setIdPlanSeleccionado] = useState('');
  const [modalCobertura, setModalCobertura] = useState(false);
  const [detalleCobertura, setDetalleCobertura] = useState('');
  const [alert, setAlert] = useForm({
    title: '',
    description: '',
    severity: 'info',
  });

  //ref
  const planRef = useRef(null);
  const carouselRef = useRef();

  const options = {
    items: 1,
    loop: true,
    margin: 10,
    nav: false,
    dots: false,
    autoWidth: true,
    responsive: {
      0: {
        items: 1,
        loop: true,
      },
      1400: {
        items: 2,
        loop: true,
      },
      1600: {
        items: 2,
      },
    },
  };

  useEffect(() => {
    setPasoAnterior(pasoActual);
    setPasoActual(paso);
  }, [paso, pasoActual, setPasoActual, setPasoAnterior]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (paso === 3 && isRecuperandoCotizacion) {
      changeQueryParam('paso', paso);
    }
  }, [paso, isRecuperandoCotizacion]);

  useEffect(() => {
    if (paso === 3) {
      setInitialized(true);
    }
  }, [paso]);

  useEffect(() => {
    if (
      paso === 3 &&
      promocion.promocionDisponible &&
      promocion.diasExtras !== 0
    ) {
      setShowPromocion(true);
    }
  }, [promocion, paso]);

  useEffect(() => {
    if (paso === 3 && !dataCardSeleccionada) {
      setCargando(false);
      let newPlanes = dataCotizar.planes;
      let newArray = newPlanes;

      setNumPlanes(newArray.length - 1);

      if (isRecuperandoCotizacion) {
        const planRecomendado = newArray.find(plan => plan.data.recomendado);
        const sinPlanRecomendado = newArray.filter(
          plan => !plan.data.recomendado,
        );

        setDataCardSlider(sinPlanRecomendado);
        setDataCardSlideMovil([
          planRecomendado,
          ...dataCardSlideMovil,
          ...sinPlanRecomendado,
        ]);
        setDataCardSeleccionada(planRecomendado);
      } else {
        setDataCardSlider(newArray);
        setDataCardSlideMovil(newArray);

        //llamar la funcion que realiza las cotizaciones
        cotizar(newArray);
      }
    } else if (paso === 2) {
      if (!isRecuperandoCotizacion) {
        dataCotizar.planes.forEach(plan => delete plan.data);
        setDataCardSlider([]);
        setDataCardSeleccionada('');
        setSort('');
        setDataCardSlideMovil([]);
        setPromocion({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paso, isRecuperandoCotizacion]);

  useEffect(() => {
    if (paso === 3 && planes?.length) {
      //ocultar los pasos del 3 en adelante cuando se regrese del paso del paso 4, 5....
      const nombresPasos = plan?.pasos?.map(paso => paso.id.nombre);
      const nuevosPasos = pasos?.filter(
        item => !nombresPasos?.includes(item.nombre),
      );
      setPasos(nuevosPasos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paso]);

  useEffect(() => {
    if (sort) {
      const dataCardSliderSinFiltrar = [...dataCardSlider];
      const sinCardSeleccionada = dataCardSlider.filter(
        data => data._id !== dataCardSeleccionada._id,
      );

      const sortByPrice = (a, b) => {
        const precioA = parseFloat(
          a.data?.precios.find(p => p.codigo === 'A').valor.slice(1),
        );
        const precioB = parseFloat(
          b.data?.precios.find(p => p.codigo === 'A').valor.slice(1),
        );

        return sort === 'mayor-a-menor' ? precioA - precioB : precioB - precioA;
      };

      // Ordenar versión web
      const sortedData = [...sinCardSeleccionada].sort(sortByPrice);
      if (areArraysEqual(sortedData, dataCardSlider)) {
        return;
      } else {
        setDataCardSlider(sortedData);

        const sortedDataSinFiltrar = dataCardSliderSinFiltrar.sort(sortByPrice);
        setDataCardSlideMovil(sortedDataSinFiltrar);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, dataCardSlider]);

  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };

  //Funcion que crea la bitacora si el vehiculo fue creado manualmente y crea el correo
  const crearReporte = async () => {
    const etapa = await apiGetEtapa({ codEtapa: 'COT' });

    let params = {};

    if (etapa) {
      params = {
        descripcion:
          'No se encontró el vehículo, informacion fue ingresada manualmente por el usuario.',
        tipo: 'OTROS',
        oportunidadId: idOportunidad,
        etapaId: etapa.data._id,
      };

      await apiCrearBitacora(params);
    }
  };

  const cotizar = async arrayDataSlider => {
    let newData = arrayDataSlider;
    let seleccionado = {};
    let planConError = {
      _id: '',
      error: false,
    };

    for (let index = 0; index < dataCotizar.planes.length; index++) {
      try {
   
        const plan = dataCotizar.planes[index];

        const params = {
          cotizacionID: idCotizacion,
          idPlan: {
            _id: plan._id,
          },
          dataPersonal: dataCotizar.datosPersonales.numeroIdentificacion,
          oportunidadID: idOportunidad,
          numPlanes: dataCotizar.planes.length,
        };
        const { data, success } = await apiPostCotizar(params);

        if (success && data.precios[0].valor !== '₡0,00' && data.precios[0].valor !== '$0,00' ) {
          let newCoberturas = dataCotizar.seguro.coberturas.map(cobertura => {
            return data?.coberturas.find(
              resultado => resultado.codigo === cobertura.codigo,
            );
          });


          data.coberturas = newCoberturas;

          if (!newData[index]) {
            newData[index] = {};
          }

          newData[index].data = data;

          if (Object.keys(seleccionado).length === 0) {
            setDataCardSeleccionada(newData[0]);
            seleccionado = newData[0];
          }

          const recomendado = newData.find(value => value?.data?.recomendado);

          if (recomendado) {
            setDataCardSeleccionada(recomendado);
            seleccionado = recomendado;
          }

          const planSeleccionadoId = seleccionado._id;
          const dataSlider = newData.filter(
            value =>
              value?.data?.idPlan !==
              newData[
                newData.findIndex(
                  elemento => elemento._id === planSeleccionadoId,
                )
              ].data?.idPlan,
          );

          setDataCardSlider(dataSlider);
          setDataCardSlideMovil(newData);
        } else {
          planConError = {
            _id: plan._id,
            error: true,
          };

          setDataCardSlider([...dataCardSlider, planConError]);
          setDataCardSlideMovil([...dataCardSlideMovil, planConError]);

          const contenedor = document.querySelector(
            '.texto-plan-slider-' + index,
          );
          if (contenedor) {
            contenedor.classList.add('opacity-100');
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    //comprobar que la cotizacion tenga planes cotizados
    const { cotizacionEliminada } = await apiVerificarDataCRM(idCotizacion);
    const dataRiesgo = {
      id_vehiculo: bienes.id_vehiculo,
      idCotizacion: idCotizacion,
    };

    if (dataCotizar.seguro.codigo === 'SEG-DA') {
      // const dataRiesgoAsegurado = getBienes(dataCotizar, dataRiesgo);

      const riesgoAsegurado = {
        Bienes: {
          vehiculo: { id_vehiculo: dataRiesgo.id_vehiculo, tipo: 'vehiculo' },
        },
        idCotizacion: dataRiesgo.idCotizacion,
      };
      await apiCrearRiesgoAsegurado(riesgoAsegurado);
    }

    //verficar si la cotizacion no se haya eliminado y asi poder enviar el resumen de la cotizacion
    if (!cotizacionEliminada) {
      const paramsCorreo = {
        cotizacionId: idCotizacion,
      };

      await apiEnviarResumenCotizacion(paramsCorreo);
    } else {
      navigate('/page-error-500');
    }

    if (Object.keys(seleccionado).length === 0) {
      setAlert({
        title: '¡Gracias por tu interés!',
        description:
          'En este momento no podemos continuar con tu solicitud para continuar escribenos a (505) 8647-3773',
        severity: 'warning',
      });
      setAlertCoti(true);
      setTimeout(() => {
        setAlertCoti(false);
        changeQueryParam('paso', paso - 1);
        setPaso(paso - 1);  

      }, 10000); 
      
    }
  };

  const handleSubmitPlan = async (planData, idPlan, aceptacion = false) => {
    if (planData.pasos) {
      const params = {
        idPlan,
        planesCotizados: [...dataCardSlider, dataCardSeleccionada],
        idOportunidad,
      };

      setCargando(true);
      try {
        //al seleccionar un plan se enviara us id para obtener toda la info del plan y como tambien los siguientes passos
        const { data, success } = await apiPostPlan(params);
        if (success) {
          //buscamos la suma asegurada del plan seleccionado
          // suma asegurada 91A(SEG-D) , 77D(SEG-AEG)
          const plan = dataCardSlideMovil.filter(
            planCard => planCard._id === idPlan,
          )[0];

          let sumaAsegurada;

          if (dataCotizar.seguro.codigo === 'SEG-DA') {
            sumaAsegurada = plan.data.coberturas.find(
              cobertura => cobertura.codigo === '91A',
            ).descripcion;
          } else if (dataCotizar.seguro.codigo === 'SEG-AEG') {
            sumaAsegurada = plan.data.coberturas.find(
              cobertura => cobertura.codigo === '77D',
            ).descripcion;
          } else if (dataCotizar.seguro.codigo === 'SEG-ONC') {
            sumaAsegurada = plan.data.coberturas.find(
              cobertura => cobertura.codigo === '78A',
            ).descripcion;
          } else if (dataCotizar.seguro.codigo === 'SEG-VID') {
            sumaAsegurada = plan.data.coberturas.find(
              cobertura => cobertura.codigo === '76B',
            ).descripcion;
          }
          setSumasAseguradas(sumaAsegurada);
          //* actualizamos la oportunidad a la etapa de negociacion
          const paramsOportunidadNegociacion = {
            oportunidadId: idOportunidad,
            cotizacionId: idCotizacion,
            mensajeBitacora:
              'Se ha iniciado la negociación para discutir los términos y condiciones de la cotización.',
            tipo: 'COTIZADOR',
            etapa: 'NEG',
            etapaAnterior: {
              codigo: 'COT',
              values: '',
            },
          };

          await apiActualizarOportunidad(paramsOportunidadNegociacion);

          //* actualizamos la oportunidad a la etapa de documentacion
          const paramsOportunidad = generarParamsOportunidad(
            idOportunidad,
            idCotizacion,
            'Se ha solicitado la documentación requerida para avanzar en la contratación.',
            'COTIZADOR',
            'DOC',
            'NEG',
            { plan: data._id },
          );

          const { success: isExito } =
            await apiActualizarOportunidad(paramsOportunidad);

          if (isExito) {
            const ddpEstado = aceptacion ? 'aceptado' : 'rechazado';
            const ddpMensaje = aceptacion
              ? 'Cliente aceptó'
              : 'Cliente rechazó';

            if (
              dataCotizar.seguro.codigo === 'SEG-AEG' ||
              dataCotizar.seguro.codigo === 'SEG-VID'
            ) {
              const paramsOportunidadModalDDP = generarParamsOportunidad(
                idOportunidad,
                idCotizacion,
                `${ddpMensaje} requisitos de Declaración de Padecimientos`,
                'DECLARACIONES',
                'DOC',
                'DOC',
                { DDP: ddpEstado, AEP: '' },
              );

              await apiActualizarOportunidad(paramsOportunidadModalDDP);

              if (!aceptacion) {
                setRechazado(true);
              }
            }

            if (dataCotizar.seguro.codigo === 'SEG-ONC') {
              const paramsOportunidadModalDDPO = generarParamsOportunidad(
                idOportunidad,
                idCotizacion,
                `${ddpMensaje} requisitos de Declaración de Padecimientos Oncológicos`,
                'DECLARACIONES',
                'DOC',
                'DOC',
                { DDPO: ddpEstado, AEP: '' },
              );

              await apiActualizarOportunidad(paramsOportunidadModalDDPO);

              if (!aceptacion) {
                setRechazado(true);
              }
            }

            if (dataCotizar.seguro.codigo === 'SEG-DA') {
              await crearReporte();
            }

            let numeroPasos = pasos.length;
            data?.pasos.map(paso => {
              let values = {};
              let valuesError = {};

              if (paso?.preguntas) {
                values = paso.preguntas.reduce((acc, curr) => {
                  acc[curr.value] = '';
                  return acc;
                }, {});

                valuesError = paso.preguntas.reduce((acc, curr) => {
                  if (curr.requerido) {
                    acc[curr.value + 'Error'] = false;
                  }
                  return acc;
                }, {});
              }

              let newPasos = {
                formData: values,
                formDataError: valuesError,
                formulario: renderForm(paso.id.nombre),
                nombre: paso.id.nombre,
                preguntas: paso.preguntas,
              };

              pasos.push(newPasos);
              numeroPasos = pasos.length;
              return pasos.length;
            });
            planRef.current = data._id;
            setNumPasos(numeroPasos);
            setPlan(data);
            setBeneficiarios([]);
            dataCotizar.cotizacion = planData;

            if (numeroPasos !== paso) {
              changeQueryParam('paso', paso + 1);
              setPaso(paso + 1);
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }
          }

          setCargando(false);
        } else {
          navigate('/page-error-500');
        }
      } catch (error) {
        navigate('/page-error-500');
      }
    }
  };

  const handlePrevClick = () => {
    carouselRef.current.prev();
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const aceptacionModal = aceptacion => {
    setModal(false);
    handleSubmitPlan(dataPlanSeleccionado, idPlanSeleccionado, aceptacion);
  };

  const handleMostrarModal = (planData, idPlan) => {
    if (
      dataCotizar.seguro.codigo === 'SEG-AEG' ||
      dataCotizar.seguro.codigo === 'SEG-VID' ||
      dataCotizar.seguro.codigo === 'SEG-ONC'
    ) {
      setModal(true);

      setDataPlanSeleccionado(planData);
      setIdPlanSeleccionado(idPlan);
    } else {
      handleSubmitPlan(planData, idPlan);
    }
  };

  const handleCobertura = cobertura => {
    setModalCobertura(true);
    setDetalleCobertura(cobertura);
  };

  const handlePromocion = () => {
    setShowPromocion(true);
  };

  return (
    <>
      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={alertCoti}
        setOpen={setModal}
        severity={alert.severity}
      />
      <div className="relative lg:mt-6">
        {(dataCotizar.seguro.codigo === 'SEG-AEG' ||
          dataCotizar.seguro.codigo === 'SEG-VID') &&
        modal ? (
          <ModalEnfermedades
            open={modal}
            aceptacionModal={aceptacionModal}
            setOpen={setModal}
          />
        ) : dataCotizar.seguro.codigo === 'SEG-ONC' && modal ? (
          <ModalOncologico
            open={modal}
            aceptacionModal={aceptacionModal}
            setOpen={setModal}
            contenido={renderBoldTextOncologico()}
          />
        ) : null}

        {showPromocion && (
          <AlertDiasGratis
            dias={promocion.diasExtras}
            open={showPromocion}
            setOpen={setShowPromocion}
          />
        )}

        <div className="relative mb-32">
          <TituloFiltros setSort={setSort} sort={sort} isFilterPlan={false} />
          <div
            className={`max-w-[100vw]  mx-auto text-center mb-1 lg:mb-12 mt-2 h-20`}
          >
            {initialized && (
              <OwlCarousel
                className="owl-theme lg:hidden owl-resultados detalles-info"
                {...options}
              >
                {detallesCotizacion?.map((detalle, index) => (
                  <div key={index}>
                    {fontBoldDetalleCotizacion(
                      detalle,
                      index,
                      detallesCotizacion.length,
                      promocion,
                      handlePromocion,
                    )}
                  </div>
                ))}
              </OwlCarousel>
            )}
            <div className="flex max-lg:hidden justify-center">
              {detallesCotizacion?.map((detalle, index) => (
                <div key={index}>
                  {fontBoldDetalleCotizacion(
                    detalle,
                    index,
                    detallesCotizacion.length,
                    promocion,
                    handlePromocion,
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="swiper-movil w-[100vw]">
            <ResultadosMovil
              data={dataCardSlideMovil}
              coberturas={dataCotizar.seguro.coberturas}
              action={handleMostrarModal}
              paso={paso}
            />
          </div>

          <div className="grid-contenedor-cotizaciones relative hidden lg:px-12 lg:grid max-w-screen-xl-[1340px] mx-autos">
            <div className="mt-16">
              <NavigationCarrouselCotizaciones numPlanes={numPlanes}>
                <button type="button" role="presentation">
                  <span aria-label="Previous">
                    <img src={Prev} alt="prev" onClick={handlePrevClick} />
                  </span>
                </button>
              </NavigationCarrouselCotizaciones>
            </div>

            <div className="bg-card rounded-md px-4 pt-0 vista-web card-beneficios">
              <div className="mt-[60px] pb-[61px] sticky top-16 bg-white z-20">
                <TextCustom
                  text="Compara beneficios"
                  component="h3"
                  className="color-general fontPSemiBold text-2xl "
                />
                <p className="text-sm mt-5 fontPRegular leading-6">
                  Tu cotización incluye opciones de{' '}
                  <span className="font-bold">
                    {dataCotizar.planes.length} Planes
                  </span>{' '}
                  , compara precios y montos de coberturas.
                </p>
              </div>

              <div
                className={
                  dataCotizar.seguro.codigo === 'SEG-ONC' ||
                  dataCotizar.seguro.codigo === 'SEG-AEG'
                    ? 'mt-6'
                    : 'mt-1'
                }
              >
                {dataCotizar?.seguro?.coberturas?.map((cobertura, index) => {
                  return (
                    <div key={cobertura.codigo}>
                      <div className="flex gap-1 items-end">
                        <TextCustom
                          text={cobertura.nombre}
                          component="div"
                          className={`py-5 px-2 pb-0 fontPSemiBold text-sm`}
                        />
                        {cobertura?.detalle && (
                          <div
                            className="cursor-pointer z-10"
                            onClick={() => handleCobertura(cobertura)}
                          >
                            <HelpIcon
                              fontSize="small"
                              sx={{
                                fill: '#0099B7',
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <TextCustom
                        text={cobertura.descripcion}
                        component="div"
                        className={`py-5 pt-2 px-2 ${
                          index + 1 !== dataCotizar.seguro.coberturas.length &&
                          'border-b'
                        } fontPRegular text-sm`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="grid-cards-cotizaciones">
              <div className="card-seleccionada-cotizacion">
                <CardSeleccionada
                  data={dataCardSeleccionada?.data}
                  idPlan={dataCardSeleccionada?._id}
                  pasoSiguiente={handleMostrarModal}
                />
              </div>
              <div className="ancho-carrousel relative">
                {initialized && (
                  <OwlCarousel
                    className="owl-theme owl-resultados owl-web "
                    ref={carouselRef}
                    {...options}
                  >
                    {dataCardSlider.map((plan, index) =>
                      index < numPlanes ? (
                        <CardResultado
                          key={index}
                          idPlan={plan._id}
                          data={plan?.data}
                          index={index}
                          pasoSiguiente={handleMostrarModal}
                          plan={plan}
                          error={plan?.error}
                          all={dataCardSlider}
                        />
                      ) : null,
                    )}
                  </OwlCarousel>
                )}
              </div>
            </div>

            <div className="mt-24">
              <NavigationCarrouselCotizaciones numPlanes={numPlanes}>
                <button type="button" role="presentation">
                  <span>
                    <img src={Next} alt="next" onClick={handleNextClick} />
                  </span>
                </button>
              </NavigationCarrouselCotizaciones>
            </div>
          </div>
        </div>

        <div className="mx-auto mb-16 lg:mb-20 mt-10">
          <div className="necesita-ayuda-bg">
            <div className="flex flex-col items-center lg:items-start">
              <TextCustom
                text="¿Necesitas ayuda?"
                variant="h2"
                className="fontPSemiBold text-white text-[22px] md:text-2xl lg:text-4xl"
              />
              <p className="fontPRegular mt-2 text-white text-xs md:text-sm lg:text-base">
                Sigue adelante en tu proceso de contratación de seguro y{' '}
                <br className="hidden md:block lg:hidden xl:block" /> aclara tus
                dudas con nosotros en un click.{' '}
                <span className="fontPSemiBold">
                  ¡Contáctanos por WhatsApp!
                </span>
              </p>
              <ButtonCustom
                text="Chatea ahora"
                width="160px"
                className="mt-7 text-sm md:text lg:text-base"
                onClick={() => {
                  window.open('https://wa.me/50686473773', '_blank');
                }}
              />
            </div>
            <div>
              <img src={Ayuda} alt="imagen-ayuda" />
            </div>
          </div>
        </div>
      </div>
      <ModalCobertura
        open={modalCobertura}
        setOpen={setModalCobertura}
        titulo={detalleCobertura?.nombre}
        cobertura={detalleCobertura?.detalle}
      />
    </>
  );
};
export default PasoResultado;
