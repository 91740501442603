// Components
import { ButtonCustom, LoaderCotizar, TextCustom } from '../../atoms';

// Assets
import Logo from '../../../assets/images/Logos/INSLogo.svg';

// Hooks
import { useCotizador } from '../../../hooks/others';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { apiEncontrarPersonaListaControl } from '../../../services/apis/Lista Control/listaControl';

//Functions
import { renderContactarControlValidacion } from '../../../common/constants/textoModales';

//Components
import { ModalControlValidacion } from '../../organisms';

const CardResultado = ({
  all = [],
  idPlan = '',
  data = '',
  index = '',
  error = false,
  pasoSiguiente = () => null,
}) => {
  const { dataCotizar } = useCotizador();
  const [puedeComprar, setPuedeComprar] = useState(true);
  const [modalValidacion, setModalValidacion] = useState(false);

  const existeData = () => {
    const newData = all.find(dat => dat._id === idPlan)?.data;
    if (newData) {
      data = newData;
      return true;
    } else {
      return false;
    }
  };

  const onClick = async () => {
    const identificacion = dataCotizar.datosPersonales.numeroIdentificacion;
    let puedeComprar = true;

    const respuesta = async () => {
      const resp = await apiEncontrarPersonaListaControl({
        id_persona: identificacion,
      });
      return resp;
    };
    const resp = await respuesta();

    if (resp.data.no_cotizar) {
      puedeComprar = false;
    } else {
      puedeComprar = true;
    }

    if (puedeComprar) {
      pasoSiguiente(data, idPlan);
    } else {
      setModalValidacion(true);
    }
  };

  return (
    <div
      className={`${
        error ? 'bg-card-error' : 'bg-card'
      } h-full rounded-md px-4 pt-0 relative `}
      style={{ width: 248, display: 'inline-block' }}
    >
     
      {error ? (
        <div className="flex text-center items-center justify-between w-full h-full fontPSemiBold color-general text-[25px]">
          Resultado no disponible
        </div>
      ) : existeData() === false ? (
        <LoaderCotizar type="full" className={`texto-plan-slider-${index}`} />
      ) : (
        <>
          <div className="bg-[#E5EBEF] rounded-t-md absolute w-full left-0 py-1 px-2">
            <span className=" text-[#003763] fontPSemiBold text-sm">
              Plan {data.orden ? data.orden : index + 1}
            </span>
          </div>

          <div className="mt-14 pt-1 text-center border-b sticky top-16 bg-white z-10">
            <img
              src={Logo}
              alt="Imagen de aseguradora"
              width={150}
              className="mx-auto"
            />
            <TextCustom
              text={data.codigoComercial}
              component="p"
              className="fontPRegular color-general text-xs -mt-[5px]"
            />
            {data?.precios?.map((precio, index) => {
              if (precio?.codigo === 'A') {
                return (
                  <div className="pb-[25px]" key={index}>
                    <TextCustom
                      text={precio?.valor}
                      component="p"
                      className="pt-5 px-2 fontPSemiBold color-general text-2xl"
                    />
                    <TextCustom
                      text="Prima total"
                      component="p"
                      className="pt-0 fontPRegular text-base"
                    />
                    <TextCustom
                      text="(IVA Incluido)"
                      component="p"
                      className="pt-0 fontPRegular text-xs color-gray-secondary"
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          {dataCotizar.seguro.codigo !== 'SEG-V' &&
            dataCotizar.seguro.codigo !== 'SEG-DA' &&
            dataCotizar.seguro.codigo !== 'SEG-VID' &&
            dataCotizar.seguro.codigo !== 'SEG-AEG' &&
            dataCotizar.seguro.codigo !== 'SEG-ONC' && (
              <div className="border-b pb-5 text-center mt-4 h-12">
                <TextCustom
                  text="Prima Mensual:"
                  className=" color-ligth-gray fontPRegular text-sm"
                  component="p"
                  variant="p"
                />
                {data?.precios?.map(
                  (precio, index) =>
                    precio?.codigo === 'M' && (
                      <div key={index}>
                        <TextCustom
                          text={precio?.valor ? precio?.valor : 'No aplica'}
                          className="fontPSemiBold text-sm"
                          component="p"
                          variant="p"
                        />
                      </div>
                    ),
                )}
              </div>
            )}
          <div className="mt-1 text-center">
            {data?.coberturas.map((resultado, index) => {
              // Verificar si resultado?.descripcion es cero o está vacío
              const esCero = /^\$0\.00|₡0\.00|\$0,00|₡0,00$/.test(
                resultado?.descripcion,
              );

              // Si es cero y el código es '78O' o '77P', mostrar 'Amparado'
              if (
                esCero &&
                (resultado?.codigo === '78O' || resultado?.codigo === '77P')
              ) {
                return (
                  <TextCustom
                    text="Amparado"
                    component="div"
                    className={`py-[34px] px-2 ${
                      index + 1 !== data?.coberturas.length && 'border-b'
                    } fontPSemiBold text-sm`}
                    key={resultado?.codigo}
                  />
                );
              }

              // Si no es cero, mostrar la descripción original
              if (!esCero) {
                return (
                  <TextCustom
                    text={resultado?.descripcion}
                    component="div"
                    className={`py-[34px] px-2 ${
                      index + 1 !== data?.coberturas.length && 'border-b'
                    } fontPSemiBold text-sm`}
                    key={resultado?.codigo}
                  />
                );
              }

              // Si el resultado es cero y el código no es '78O' ni '77P', no renderizar nada
              return null;
            })}
          </div>

          <div className="text-center py-10">
            <ButtonCustom
              text={`${data?.pasos ? 'Comprar' : 'Solicitar'}`}
              width="160px"
              className="fontPSemiBold"
              onClick={onClick}
            />
          </div>
          <Link
            to={data?.linkCondiciones}
            className="fontPRegular color-general text-sm flex justify-center underline cursor-pointer"
            target="_blank"
          >
            Condiciones Generales
          </Link>
        </>
      )}
       <ModalControlValidacion
        open={modalValidacion}
        setOpen={setModalValidacion}
        titulo="¡Verificación necesaria!"
        contenido="En este momento no podemos proceder debido a una revisión de nuestros registros"
        contactar={renderContactarControlValidacion()}
      />
    </div>
  );
};

export default CardResultado;
