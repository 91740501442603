// Components
import { MenuItem } from '@mui/material';

// Core
import { colors } from '../../styles/theme';

const MenuItemCustom = ({
  children = null,
  value = '',
  alinear = '',
  ...rest
}) => {
  return (
    <MenuItem
      value={value}
      className={`fontPRegular ${
        alinear === 'izquierda' ? 'justify-start' : 'justify-center'
      } `}
      sx={{
        '&:hover': {
          backgroundColor: '#E5F4F7',
          color: colors.general,
          borderRadius: '10px',
        },
        '&.Mui-selected': {
          backgroundColor: colors.hoverSelect,
          color: colors.general,
        },
        '&.Mui-selected:hover': {
          backgroundColor: colors.hoverSelect,
          color: colors.general,
        },
      }}
      {...rest}
    >
      {children}
    </MenuItem>
  );
};

export default MenuItemCustom;
