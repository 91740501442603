import { useLocation } from 'react-router-dom';

// Components
import { CardAccordion } from '../../molecules';

// Style
import './style.css';

// Const
import { preguntasAsegurame } from '../../../common/constants';

const PreguntasFrecuentes = () => {
  const location = useLocation();

  // Assets
  const FAQ =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/FAQ/Asegurame-PreguntasFrecuentes.webp';

  return (
    <section className="bg">
      <div className="px-4 pb-20 mt-24 md:px-10 lg:px-0 xl:px-0 2xl:px-28 container mx-auto altura-pantalla flex items-center">
        <div>
          <div className="grid-faq contenedor-faq">
            <img src={FAQ} alt="Imagen principal" className="imagen" />
            <div className="contenido-faq text-white">
              <h1 className=" fontPSemiBold">¿Tienes preguntas?</h1>
              <p className="fontPRegular">
                Aquí encontrarás respuestas a las preguntas más comunes{' '}
                <br className="salto-linea" /> que nuestros usuarios suelen
                hacer.
              </p>
            </div>
          </div>

          <div className="contenedor-preguntas w-full">
            <CardAccordion
              options={preguntasAsegurame}
              location={location.state}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PreguntasFrecuentes;
