//components
import { TitulosTexto } from '../../molecules';

//assets
import { ReactComponent as Forma1 } from '../../../assets/images/home/Forma-Asegurame-1.svg';
import { ReactComponent as LogoUniserse } from '../../../assets/images/Logos/Uniserse-Licencia.svg';
import { ReactComponent as WhatsAppIcono } from '../../../assets/icons/WhatsApp-Icono.svg';

//styles
import './style.css';
import { TextCustom } from '../../atoms';
import { Link } from 'react-router-dom';

const SeccionPrincipalHome = () => {
  // Assets
  const ImgPrincipal =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/home/Home-Portada.webp';

  const handleScroll = () => {
    const btn = document.getElementById('cont_Scroll');
    btn.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
      inline: 'center',
    });
  };

  return (
    <section className="text-center bg-degrado-principal contenedor-home relative">
      <Forma1 className="hidden absolute top-1/3 right-0 lg:block" />
      <div className="contenedor-home-tam">
        <div className="grid-seccion-principal">
          <div className="flex justify-center items-center">
            <div className="xl:absolute lg:text-left contenedor-contenido">
              <TitulosTexto
                titulo={`*Cotiza y compara* \n de la forma mas sencilla`}
                subtitulo={`Con Asegurame: Tu plataforma digital de seguros \n y de mano de la experiencia y servicio de UNISERSE, \n encuentra tu seguro ideal.`}
                className="titulo-principal"
                subtituloPrincipal={true}
                showSeguros={true}
              />
            </div>
          </div>
          <div className="imagen-hero">
            <img src={ImgPrincipal} alt="ImgPrincipal" />
          </div>
        </div>
        <div className="contenedor-icono">
          <div onClick={handleScroll} className="icon-seccion">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="arrow-circle-down"
              viewBox="0 0 24 24"
              width="32"
              height="32"
              className="fill-primary"
            >
              <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,16a2.993,2.993,0,0,1-1.987-.752c-.327-.291-.637-.574-.84-.777L6.3,11.647a1,1,0,0,1,1.4-1.426L10.58,13.05c.188.187.468.441.759.7a1,1,0,0,0,1.323,0c.29-.258.57-.512.752-.693L16.3,10.221a1,1,0,1,1,1.4,1.426l-2.879,2.829c-.2.2-.507.48-.833.769A2.99,2.99,0,0,1,12,16Z"></path>
            </svg>
          </div>
        </div>
       
      </div>
      <hr />
    </section>
  );
};

export default SeccionPrincipalHome;
