import React, { useRef } from 'react';

// Components
import { ButtonCustom } from '../../atoms';
import { DialogCustom } from '../../templates';
import { DialogActions, DialogContent } from '@mui/material';

// Functions
import { convertCoberturas } from '../../../core/utils';

const ModalCobertura = ({
  open = false,
  setOpen = () => null,
  titulo = '',
  cobertura = '',
}) => {
  const contenidoRef = useRef(null);

  return (
    <DialogCustom open={open} setOpen={setOpen} title={titulo} disabledDismiss>
      <DialogContent className="pt-0 px-0  md:px-6 fontPRegular overflow-x-hidden">
        <div className="relative my-5 w-auto lg:w-[600px]" ref={contenidoRef}>
          {open && (
            <div
              dangerouslySetInnerHTML={{ __html: convertCoberturas(cobertura) }}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-center w-full">
          <ButtonCustom
            text="Ok"
            typeColor="primary"
            onClick={() => setOpen(false)}
            width={'130px'}
            height={'45px'}
          />
        </div>
      </DialogActions>
    </DialogCustom>
  );
};

export default ModalCobertura;
