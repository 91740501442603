// Styles
import './style.css';
import { colors } from '../../styles/theme';

const { white } = colors;

const ButtonCards = ({
  enabled = false,
  icon = null,
  id = '',
  label = '',
  descripcion = '',
  size = 200,
  value = '',
  onClick = () => null,
  errorAlert = false,
}) => {
  const handleClick = () => {
    !enabled && onClick();
  };

  return (
    <div>
      <div
        className="m-2 flex flex-col items-center card-buttom gap-3"
        style={{ maxWidth: '200px' }}
      >
        <div
          className="flex justify-center items-center card-buttom-opc"
          style={{ width: size, height: size }}
        >
          <div
            className={`asegurame-rb-icon ${
              value === id
                ? 'asegurame-rb-icon-selected asegurame-select-icon '
                : 'asegurame-card' + id
            }`}
            id={id}
            onClick={handleClick}
            style={{
              backgroundColor: !enabled ? white : 'var(--color-optional-gray)',
              border: errorAlert && '2px solid var(--color-error)',
            }}
          >
            <img src={icon} alt={label} style={{ width: 80, height: 80 }}  className={`${
              value === id
                ? 'filter-selected '
                : ''
            }`}/>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="fontPSemiBold text-base color-general">{label}</span>
          <span className="fontPRegular text-sm color-general lg:text-[#222222]">
            {descripcion}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ButtonCards;
