export const colors = {
  general: '#003763',
  primary: '#0099B7',
  secondary: '#A01779',
  green: '#76BC21',
  neutral: '#efefef',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#3C3C3B',
  texto: '#393e43',
  ligthGray: '#CDCDCD',
  disable: '#e9ecef',
  error: '#ED3B6B',
  warning: '#EDA93B',
  bordes: '#e3e3e3',
  hoverSelect: '#0099b70f',
};
