import axios from 'axios';
import buildRequest from '../buildRequest';
import { typesEndpoint } from '../../../common/types';

export const apiCrearContactoComentario = async ({
  nombre,
  correo,
  seguro,
  comentario,
  tokenReCaptcha,
}) => {
  const url = `${process.env.REACT_APP_api}${typesEndpoint.contactanos}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const request = {
    nombre,
    correo,
    seguro,
    comentario,
    tokenReCaptcha: tokenReCaptcha,
  };

  try {
    const response = await axios[method](url, request, buildRequest());
    const { status, data } = response;
    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.statusCode = status;
  } catch (error) {
    dataResponse.data = error;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
};
