import { useEffect, useState } from 'react';

// Components
import { EntradasBlog, TitulosInformativa } from '../../molecules';
import { ButtonCustom } from '../../atoms';

// API
import { apiGetNotas } from '../../../services/apis';

// Functions
import { formatUrlNota, formatearFecha } from '../../../core/utils';

//style
import './style.css';

const Blog = () => {
  const [estaMostrando, setEstaMostrando] = useState(false);
  const [notas, setNotas] = useState([]);

  // Assets
  const AsegurameBlog =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Blog/Asegurame-Blog.webp';

  useEffect(() => {
    const cargarDatos = async () => {
      const response = await apiGetNotas();
      const { data, success } = response;

      if (success) {
        const newNota = data.map(nota => {
          let notaN = nota;
          notaN.urlSlug = formatUrlNota(notaN.titulo);
          notaN.fecha = formatearFecha(notaN.createdAt, true);
          return notaN;
        });
        setNotas(newNota);
      }
    };

    cargarDatos();
  }, []);

  const handleCargarContenido = () => {
    setEstaMostrando(!estaMostrando);
  };

  return (
    <main>
      <TitulosInformativa
        titulo="¡Hola!"
        descripcion="Bienvenido al blog de Asegurame"
        imagen={AsegurameBlog}
        className="contenedor-blog"
        classNameText="contenido-blog"
      />
      <section className="container mx-auto px-5 mt-28 mb-24 md:px-10 lg:px-12 xl:px-0 2xl:px-28  ">
        <div className="grid-blog-cards mt-20 xl:px-14">
          {notas.map((entrada, index) => (
            <EntradasBlog
              entrada={entrada}
              index={index + 1}
              key={index}
              estaMostrando={estaMostrando}
              notas={notas}
            />
          ))}
        </div>
        <div className="flex justify-center mt-10">
          {notas.length > 6 && (
            <ButtonCustom
              text={` ${estaMostrando ? 'Cargar Menos' : 'Cargar más'} `}
              typeColor="primary"
              width="200px"
              height="40px"
              onClick={handleCargarContenido}
            />
          )}
        </div>
      </section>
    </main>
  );
};

export default Blog;
