import { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';

//copmponentes
import { TextCustom, TextInputSalto } from '../../atoms';

//assets

// Styles Carousel
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const CardNosotros = () => {
  const [initialized, setInitialized] = useState(false);

  // Assets
  const AsesorAmigo =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Conocenos/Asegurame-AsesorAmigo.webp';
  const ExperienciaCertificacion =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Conocenos/Asegurame-Experiencia.webp';
  const PortalLinea =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Conocenos/Asegurame-PortalEnLinea.webp';

  const cards = [
    {
      codigo: 'PL',
      imagen: PortalLinea,
      titulo: 'Portal en línea',
      descripcion:
        'Elige, cotiza, compara y adquiere \n tus seguros de manera digital.',
    },
    {
      codigo: 'AA',
      imagen: AsesorAmigo,
      titulo: 'Asesor amigo',
      descripcion:
        'Todo el capital humano de UNISERSE a tu servicio para acompañarte y asesorarte.',
    },
    {
      codigo: 'EC',
      imagen: ExperienciaCertificacion,
      titulo: 'Experiencia y certificación',
      descripcion: ' Más de 27 años acompañando \n a nuestro clientes.',
    },
  ];

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [initialized]);

  const options = {
    items: 1,
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 3000,
  };

  return (
    <div className="px-7 mx-auto lg:container">
      <section className="px-4 conocenos-tarjetas pb-16 pt-5 hidden xsm:block">
        <div className="flex-tarjetas-cards py-12">
          {cards.map((item, index) => (
            <div
              key={index}
              className="card fontPSemiBold hover:scale-105 transition-all duration-300"
            >
              <div className="card-img">
                <img
                  src={item.imagen}
                  alt={item.titulo}
                  className={`${
                    item.codigo === 'EC'
                      ? 'w-[200px]'
                      : item.codigo === 'AA'
                      ? 'w-[250px]'
                      : 'w-[250px]'
                  }`}
                />
              </div>
              <h3 className="color-general">{item.titulo}</h3>
              <div className="card-contenido my-3">
                <TextInputSalto
                  className="text-base fontPRegular text-[#222]"
                  texto={item.descripcion}
                />
              </div>
            </div>
          ))}
        </div>
      </section>

      {initialized && (
        <OwlCarousel
          {...options}
          className="owl-theme w-[calc(100%+3rem)] nosotros-carousel -ml-6 mt-0 mb-5 xsm:hidden "
        >
          {cards.map((item, index) => (
            <div
              key={index}
              className="m-1 bg-card w-[19rem] h-[18rem] mx-auto mt-7"
            >
              <div className="flex justify-center">
                <img
                  src={item.imagen}
                  alt={item.titulo}
                  className={`${
                    item.codigo === 'EC' ? 'w-[140px]' : 'w-[185px]'
                  }`}
                />
              </div>
              <div className="text-center mx-auto mt-4">
                <TextCustom
                  text={item.titulo}
                  component="p"
                  variant="p"
                  className="color-general fontPSemiBold"
                />
                <TextCustom
                  text={item.descripcion}
                  component="p"
                  variant="p"
                  className="text-sm fontPRegular text-[#222] mt-3"
                />
              </div>
            </div>
          ))}
        </OwlCarousel>
      )}
    </div>
  );
};

export default CardNosotros;
