import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import queryString from 'query-string';

import { Link, useLocation } from 'react-router-dom';

// Components
import { LoaderCotizar, TextCustom } from '../../atoms';
import { CardCotizacion } from '../../molecules';

// Assets
import sello from '../../../assets/images/Cotizacion/Sello.svg';

// Styles
import './style.css';

// Hooks
import { useCotizador, useApp } from '../../../hooks/others';

const Cotizar = () => {
  let location = useLocation();
  const { seguros } = useApp();
  const { resetearContext } = useCotizador();
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (Object.keys(parsed).length !== 0) {
      const query = parsed.aliado;
      Cookies.set('_al', query, { expires: 365 });
    }

    resetearContext();
    setTimeout(() => {
      setCargando(false);
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {cargando && <LoaderCotizar />}
      <section className="bg flex justify-center items-center mt-3 lg:mt-0 min-h-[calc(100vh-160px)] xs:min-h-[calc(100vh-84px)] md:min-h-[calc(100vh-152px)] xl:min-h-[calc(100vh-84px)] 2xl:min-h-[calc(100vh-136px)]">
        <div className="px-4 pb-2 my-16 md:px-28 lg:px-10 xl:px-0 2xl:px-16 container mx-auto fontPRegular altura-pantalla items-center relative">
          <div className="text-center">
            <TextCustom
              text="Resultado en "
              className="color-primary text-xs fontPMedium xs:text-sm xlg:text-base"
            />
            <span className="text-white bg-primary px-2 rounded-full text-xs xs:text-sm xlg:text-base">
              menos de 3 minutos
            </span>
          </div>
          <img
            src={sello}
            alt="Sello 100% Digital"
            className="max-h-[14%] max-w-[14%] absolute right-[5.5%] -top-4 md:-top-8 ssm:right-[40px] md:right-[90px] lg:right-[150px] xlg:right-[120px] xl:right-[270px] 2xl:right-[400px]"
          />
          <TextCustom
            text="Cotiza y compara seguros"
            variant="h2"
            className="color-general text-center fontPSemiBold text-[21px] mt-2 xs:text-[23px] md:text-3xl lg:text-4xl"
          />

          <p className="mt-2 lg:mt-4 leading-5 text-center text-sm text-[#393E43] xs:text-base md:text-base">
            Selecciona una opción de seguro para <b className="" />
            comparar y ver precios de varias aseguradoras.
          </p>

          <div
            className={`mt-14 lg:mt-5 grid gap-5 justify-center lg:grid-cols-2  xlg:mt-16 ${
              seguros.length >= 5
                ? 'xlg:grid-cols-5 xlg:gap-6'
                : 'xlg:flex xlg:flex-wrap '
            }`}
          >
            {seguros?.map(seguro => (
              <CardCotizacion
                seguro={seguro.nombre}
                key={seguro.nombre}
                data={seguro}
              />
            ))}
          </div>
          <p className="text-[#222222] text-xs text-center mt-10 leading-5 xlg:text-[#999999]">
            Al dar clic en continuar estarás aceptando
            <br className="md:hidden" /> los{' '}
            <Link
              to="https://asegurame.org/pdf/Terminos%20y%20Condiciones.pdf"
              className="underline"
            >
              Términos y Condiciones
            </Link>{' '}
            de Asegurame
          </p>
        </div>
      </section>
    </>
  );
};

export default Cotizar;
