import { Link } from 'react-router-dom';
import { TextCustom } from '../../atoms';
import { ReactComponent as LogoUniserse } from '../../../assets/images/Logos/Uniserse-Licencia.svg'
import { ReactComponent as WhatsAppIcono } from '../../../assets/icons/WhatsApp-Icono.svg';

const LicenseUniserseWA = () => {
  return (
    <div className="fixed right-0 bottom-3 z-10  ">
      <div className="flex justify-center xl:justify-end mt-16 md:mt-14 lg:mt-0">
        <div className="flex justify-center items-center flex-row lg:items-center gap-3  ">
          <Link to={'https://linktr.ee/uniserse'} target="_blank">
            <TextCustom className="fontPSemiBold color-general text-left " />
            <LogoUniserse className="max-w-[170px] max-h-[150px] mt-1 max-lg:max-w-[120px]" />
          </Link>

          <Link to={'https://wa.me/50686473773'} target="_blank">
            <WhatsAppIcono className="max-w-[98px] max-h-[78px]  cursor-pointer  max-lg:max-w-[58px] max-lg:max-h-[58px]   " />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LicenseUniserseWA;
