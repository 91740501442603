import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Hotjar from '@hotjar/browser';
import Cookies from 'js-cookie';
import queryString from 'query-string';
// Components
import { Toolbar } from '@mui/material';
import { HeaderMenu } from '../../organisms';
import { Footer } from '../../organisms';

//CSS
import './style.css';
import { useApp } from '../../../hooks/others';
import { LicenseUniserseWA } from '../../molecules';

const Layout = ({ children }) => {
  const location = useLocation();
  const { recaptchaRef } = useApp();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (process.env.REACT_APP_google === 'true') {
      const siteID = process.env.REACT_APP_hotjar;
      const hotjarVersion = 6;

      Hotjar.init(siteID, hotjarVersion);
    }

    const aliado = Cookies.get('_al');
    const parsed = queryString.parse(location.search);

    if (Object.keys(parsed).length === 0 && aliado !== 'UNISER') {
      Cookies.set('_al', 'UNISER', { expires: 365 });
    }
  }, []);

  return (


    <div>
      <HeaderMenu />
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        badge="bottomleft"
        className="recaptcha"
        ref={recaptchaRef}
        size="invisible"
      />

      <LicenseUniserseWA/>
      <Toolbar />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
