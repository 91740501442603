//componets
import { TitulosTexto, CardsPersonal } from '../../molecules';

//assets
import Forma4 from '../../../assets/images/home/Forma-Asegurame-4.svg';

//styles
import './style.css';

const SeccionPersonal = ({ svgFigura = false }) => {
  return (
    <section className="2xl:container mx-auto px-5 md:px-7 py-20 contenedor-personal relative">
      {svgFigura && (
        <img
          src={Forma4}
          alt="forma 4"
          className="hidden absolute top-1/3 -left-44 lg:block"
        />
      )}

      <div className="text-center mb-2 texto">
        <TitulosTexto
          titulo={`Equipo *Asegurame* \n de Corredores`}
          // subtitulo={`Estamos revolucionando la industria de seguros. \n Te ayudaremos de manera objetiva a contratar el seguro perfecto para ti.`}
          className="text-sm titulo-personal "
        />
      </div>
      <div className="gap-10 mt-10">
        <CardsPersonal />
      </div>
    </section>
  );
};

export default SeccionPersonal;
