import { formatearFecha } from '../../../core/utils';
import { useCotizador } from '../../../hooks/others';
import { TextCustom } from '../../atoms';

export default function ComprandoOtroSeguroViaje() {
  const { dataCotizar, detallesCotizacion } = useCotizador();

  return (
    <div className="bg-general rounded-[20px] p-5 mb-10  md:h-[130px] xl:h-[160px] ">
      <div className="flex justify-between 2xl:w-[90%] 2xl:mx-auto relative">
        <div className="w-[85%] ">
          <TextCustom
            text="Continua comprando:"
            component="p"
            className="text-white fontPRegular text-[10px] md:text-base"
          />
          <TextCustom
            text={dataCotizar.dataSeguro?.tipoPlan}
            component="p"
            className="text-white fontPSemiBold text-[22px] xl:text-[35px]"
          />

          <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-2">
            <TextCustom
              text={
                <>
                  Destino: <span className="fontPRegular ">{detallesCotizacion[1].split(":")[1]}</span>
                </>
              }
              component="p"
              className="text-white text-[10px] fontPBold md:text-sm xl:text-base"
            />
            <TextCustom
              text={
                <>
                  Fecha de salida:{' '}
                  <span className="fontPRegular ">
                    {formatearFecha(
                      dataCotizar.dataSeguro?.fechaViaje?.split('-').at(0),
                    )}
                  </span>
                </>
              }
              component="p"
              className="text-white fontPBold text-[10px] md:text-sm xl:text-base"
            />
            <TextCustom
              text={
                <>
                  Fecha de regreso:{' '}
                  <span className="fontPRegular">
                    {formatearFecha(
                      dataCotizar.dataSeguro?.fechaViaje?.split('-').at(1),
                    )}
                  </span>
                </>
              }
              component="p"
              className="text-white fontPBold text-[10px] md:text-sm xl:text-base"
            />
          </div>
        </div>
        <div className="absolute -right-10 -top-10 min-[375px]:-right-2 md:-top-10 xl:-top-7">
          <img
            src="https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/ViajeSalida/Icono-Big.svg"
            alt="Imagen seguro viaje"
            className="w-24 h-44 min-[375px]:w-28 md:w-28 xl:w-32"
          />
        </div>
      </div>
    </div>
  );
}
