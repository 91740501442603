import { param } from "jquery";
import { typesEndpoint } from "../../../common/types";
import buildRequest from '../buildRequest';
import axios from 'axios';




export const enviarCorreoVerificacion = async params => {
    const url = `${process.env.REACT_APP_api}${typesEndpoint.correoVerificacion}`;
    const method = 'post';
    const dataResponse = {
      success: false,
      statusCode: 0,
      message: '',
      data: [],
    };
  
    const request = {
      ...params
    };
  
    try {
      const response = await axios[method](url, request, buildRequest());
      const { status, data } = response;
  
      dataResponse.success = true;
      dataResponse.data = data.data;
      dataResponse.statusCode = status;
    } catch (error) {
      dataResponse.data = error;
      dataResponse.statusCode = error.response?.status;
    }
    return dataResponse;
  };


  export const enviarCorreoFacturacion = async params => {
    const url = `${process.env.REACT_APP_api}${typesEndpoint.correoFacturacion}`;
    const method = 'post';
    const dataResponse = {
      success: false,
      statusCode: 0,
      message: '',
      data: [],
    };
  
    const request = {
      nombre: params.nombre,
      cedula: params.cedula,
      correo: params.correo,
      idOportunidad: params.idOportunidad
    };
  
    try {
      const response = await axios[method](url, request, buildRequest());
      const { status, data } = response;
  
      dataResponse.success = true;
      dataResponse.data = data.data;
      dataResponse.statusCode = status;
    } catch (error) {
      dataResponse.data = error;
      dataResponse.statusCode = error.response?.status;
    }
    return dataResponse;
  };