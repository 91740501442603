export const renderBoldText = () => (
  <p className="whitespace-pre-wrap text-justify">
    Declaro que he recibido la información previa al perfeccionamiento y sobre
    las Condiciones Generales de este seguro, además manifiesta que las ha
    entendido, las acepta libremente y entiende que, al suscribir su solicitud,
    el seguro podrá ser emitido bajo tales condiciones. <br />
    <br />
    He sido enterado de que la expedición de este contrato y la cobertura de
    esta póliza está sujeta al pago de la prima. Las Condiciones Generales y el
    DERSA de este seguro pueden consultarlas a través de la dirección
    electrónica: <span className="font-bold">www.grupoins.com.</span>{' '}
    Adicionalmente ponemos a su disposición el número{' '}
    <span className="font-bold">800-TeleINS (800-8353467)</span> y el correo
    electrónico <span className="font-bold">contactenos@grupoins.com</span>{' '}
    Nuestras oficinas centrales se encuentran ubicadas en San José, Central,
    calles 9 y 9 Bis, avenida 7; para gestionar preguntas frecuentes y recibir
    asesoría sobre su seguro.
    <br />
    <br /> 
    Declaro que la información provista en este formulario es fiel y
    verdadera, por tanto, acepto que cualquier omisión o falsedad en los datos
    suministrados causaría la cancelación de los contratos de seguros; asimismo,
    mientras exista una relación comercial, me comprometo a mantener informado
    al INS sobre los cambios que puedan presentarse a futuro, con respecto a
    dicha información.
    <br />
    <br />
    Entiendo y apruebo que el INS incluirá los datos del asegurado y/o tomador a
    una base, bajo su responsabilidad, que cuenta con medidas de seguridad
    adecuadas. El INS podrá trasladar los datos a sus colaboradores, partes
    vinculadas, proveedores, intermediarios y socios comerciales, y a las
    empresas que conforman el Grupo Financiero INS, con el fin de ejecutar el
    contrato u ofrecer productos o servicios adicionales, todo sujeto a las
    políticas y normativa sobre protección de datos. El INS podrá actualizar los
    datos con información de bases públicas o privadas. Esta manifestación
    constituye consentimiento informado, por lo que el tomador o asegurado
    tienen derecho a la rectificación, restricción o supresión de sus datos de
    la base conformada por el INS, sin que ello afecte los datos registrados en
    los contratos. <br />
    <br />
    <p className="font-bold">
      Información previa al perfeccionamiento del contrato de seguros
    </p>
    Al seleccionar “Aceptar” declaro que, al realizar la cotización en cada uno
    de los planes presentados se ha presentado la información contractual,
    exclusiones y coberturas del seguro que está a punto de adquirir. Si tiene
    alguna duda sobre está información, puede realizar sus consultas a través de
    nuestro WhatsApp corporativo o bien al{' '}
    <span className="font-bold"> 800-TELEINS(800-8353467). </span>
  </p>
);



export const renderContactarControlValidacion = () =>(
<p className="whitespace-pre-wrap text-justify">
  Para más información contáctanos al{' '}
  <a href="tel:+50686473773" className="font-bold text-blue-500 hover:underline">
    (506) 8647-3773
  </a>{' '}
  ¡Estamos aquí para ayudarte!
</p>


)






export const renderBoldTextOncologico = () => (
  <p className="whitespace-pre-wrap text-justify">
    <p className="font-bold">
      Aceptación: Perfeccionamiento del contrato y declaración jurada emisión de
      Pólizas
    </p>
    Declaro que he recibido la información previa al perfeccionamiento y sobre
    las Condiciones Generales de este seguro, además manifiesta que las ha
    entendido, las acepta libremente y entiende que, al suscribir su solicitud,
    el seguro podrá ser emitido bajo tales condiciones. <br />
    <br />
    He sido enterado de que la expedición de este contrato y la cobertura de
    esta póliza está sujeta al pago de la prima. Las Condiciones Generales y el
    DERSA de este seguro pueden consultarlas a través de la dirección
    electrónica: <span className="font-bold">www.grupoins.com.</span>{' '}
    Adicionalmente ponemos a su disposición el número{' '}
    <span className="font-bold">800-TeleINS (800-8353467)</span> y el correo
    electrónico <span className="font-bold">contactenos@grupoins.com</span>{' '}
    Nuestras oficinas centrales se encuentran ubicadas en San José, Central,
    calles 9 y 9 Bis, avenida 7; para gestionar preguntas frecuentes y recibir
    asesoría sobre su seguro.
    <br />
    <br /> 
    Declaro que la información provista en este formulario es fiel y
    verdadera, por tanto, acepto que cualquier omisión o falsedad en los datos
    suministrados causaría la cancelación de los contratos de seguros; asimismo,
    mientras exista una relación comercial, me comprometo a mantener informado
    al INS sobre los cambios que puedan presentarse a futuro, con respecto a
    dicha información.
    <br />
    <br />
    Entiendo y apruebo que el INS incluirá los datos del asegurado y/o tomador a
    una base, bajo su responsabilidad, que cuenta con medidas de seguridad
    adecuadas. El INS podrá trasladar los datos a sus colaboradores, partes
    vinculadas, proveedores, intermediarios y socios comerciales, y a las
    empresas que conforman el Grupo Financiero INS, con el fin de ejecutar el
    contrato u ofrecer productos o servicios adicionales, todo sujeto a las
    políticas y normativa sobre protección de datos. El INS podrá actualizar los
    datos con información de bases públicas o privadas. Esta manifestación
    constituye consentimiento informado, por lo que el tomador o asegurado
    tienen derecho a la rectificación, restricción o supresión de sus datos de
    la base conformada por el INS, sin que ello afecte los datos registrados en
    los contratos. <br />
    <br />
    <p className="font-bold">
      Información previa al perfeccionamiento del contrato de seguros
    </p>
    Al seleccionar “Aceptar” declaro que, al realizar la cotización en cada uno
    de los planes presentados se ha presentado la información contractual,
    exclusiones y coberturas del seguro que está a punto de adquirir. Si tiene
    alguna duda sobre está información, puede realizar sus consultas a través de
    nuestro WhatsApp corporativo o bien al{' '}
    <span className="font-bold"> 800-TELEINS(800-8353467). </span>
  </p>
);
