import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';

//componets
import { TitulosTexto } from '../../molecules';

import { ReactComponent as Adisa } from '../../../assets/images/Logos/Adisa.svg';
import { ReactComponent as ASSA } from '../../../assets/images/Logos/ASSA.svg';
import { ReactComponent as BMI } from '../../../assets/images/Logos/BMI.svg';
import { ReactComponent as InsLogo } from '../../../assets/images/Logos/INSLogo.svg';
import { ReactComponent as Lafise } from '../../../assets/images/Logos/Lafise.svg';
import { ReactComponent as MAPFRE } from '../../../assets/images/Logos/MAPFRE.svg';
import { ReactComponent as Oceanica } from '../../../assets/images/Logos/Oceanica.svg';
import { ReactComponent as PanAmericaLifeInsurance } from '../../../assets/images/Logos/PanAmericaLifeInsurance.svg';
import { ReactComponent as Qualitas } from '../../../assets/images/Logos/Qualitas.svg';
import { ReactComponent as SMSeguros } from '../../../assets/images/Logos/SMSeguros.svg';

// Styles Carousel
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const SeccionAseguradoras = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [initialized]);

  const options = {
    items: 6,
    loop: true,
    margin: 10,
    nav: false,
    autoWidth: true,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 2,
        center: true,
      },
      1100: {
        items: 4,
      },
      1400: {
        items: 6,
      },
    },
  };

  return (
    <section className="lg:container mx-auto pt-0 pb-20 px-0 md:px-7 md:py-20 contenedor-personal">
      <div className="text-center mb-2 texto w-[90%] mx-auto">
        <TitulosTexto
          titulo={`*Aseguradoras* \n que trabajan con nosotros`}
          // subtitulo={`Estamos revolucionando la industria de seguros. \n Te ayudaremos de manera objetiva a contratar el seguro perfecto para ti.`}
          className="text-sm titulo-personal "
        />
      </div>
      <div className="gap-10 mt-10">
        {initialized && (
          <OwlCarousel className="owl-theme" {...options}>
            <div className="bg-card flex justify-center w-[225px] h-[85px] max-w-[225px] max-h-[110px]">
              <Adisa className="max-w-[146px] max-h-[50px]" />
            </div>
            <div className="bg-card flex justify-center w-[225px] h-[85px] max-w-[225px] max-h-[110px]">
              <ASSA className="max-w-[146px] max-h-[50px]" />
            </div>
            <div className="bg-card flex justify-center w-[225px] h-[85px] max-w-[225px] max-h-[110px]">
              <BMI className="max-w-[146px] max-h-[50px]" />
            </div>
            <div className="bg-card flex justify-center w-[225px] h-[85px] max-w-[225px] max-h-[110px]">
              <InsLogo className="max-w-[146px] max-h-[50px]" />
            </div>
            <div className="bg-card flex justify-center w-[225px] h-[85px] max-w-[225px] max-h-[110px]">
              <Lafise className="max-w-[146px] max-h-[50px]" />
            </div>
            <div className="bg-card flex justify-center w-[225px] h-[85px] max-w-[225px] max-h-[110px]">
              <MAPFRE className="max-w-[146px] max-h-[50px]" />
            </div>
            <div className="bg-card flex justify-center w-[225px] h-[85px] max-w-[225px] max-h-[110px]">
              <Oceanica className="max-w-[146px] max-h-[50px]" />
            </div>
            <div className="bg-card flex justify-center w-[225px] h-[85px] max-w-[225px] max-h-[110px]">
              <PanAmericaLifeInsurance className="max-w-[146px] max-h-[50px]" />
            </div>
            <div className="bg-card flex justify-center w-[225px] h-[85px] max-w-[225px] max-h-[110px]">
              <Qualitas className="max-w-[146px] max-h-[50px]" />
            </div>
            <div className="bg-card flex justify-center w-[225px] h-[85px] max-w-[225px] max-h-[110px]">
              <SMSeguros className="max-w-[146px] max-h-[50px]" />
            </div>
          </OwlCarousel>
        )}
        <div className="grid gap-5 justify-center md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6"></div>
      </div>
    </section>
  );
};

export default SeccionAseguradoras;
